import { inject, Injectable } from '@angular/core';
import { Dict } from 'mixpanel-browser';

import { AnalyticsService } from '@@core/services/analytics/analytics.service';

import { AnalyticsPayloadContextProvider, ManualTestPayloadContext, TrackActionType } from '../../models/manual-test-analytics.model';
import { ManualTestAnalyticsEventPayloadBuilder } from './manual-test-analytics-event-payload.builder';

@Injectable({
	providedIn: 'root'
})
export class ManualTestAnalyticsService {
	readonly #analyticsService = inject(AnalyticsService);

	trackClickStart(payloadProvider: AnalyticsPayloadContextProvider<ManualTestPayloadContext>): void {
		this.#analyticsService.trackSafe(
			TrackActionType.ClickStart,
			(payload) => this.getPayloadBuilder(payload),
			payloadProvider
		);
	}

	trackClickSubmit(payloadProvider: AnalyticsPayloadContextProvider<ManualTestPayloadContext>): void {
		this.#analyticsService.trackSafe(
			TrackActionType.ClickSubmit,
			(payload) => this.getPayloadBuilder(payload),
			payloadProvider
		);
	}

	trackClickCancel(payloadProvider: AnalyticsPayloadContextProvider<ManualTestPayloadContext>): void {
		this.#analyticsService.trackSafe(
			TrackActionType.ClickCancel,
			(payload) => this.getPayloadBuilder(payload),
			payloadProvider
		);
	}

	trackClickStartNew(payloadProvider: AnalyticsPayloadContextProvider<ManualTestPayloadContext>): void {
		this.#analyticsService.trackSafe(
			TrackActionType.ClickStartNew,
			(payload) => this.getPayloadBuilder(payload),
			payloadProvider
		);
	}

	trackClickRestart(payloadProvider: AnalyticsPayloadContextProvider<ManualTestPayloadContext>): void {
		this.#analyticsService.trackSafe(
			TrackActionType.ClickRestart,
			(payload) => this.getPayloadBuilder(payload),
			payloadProvider
		);
	}

	private getPayloadBuilder(payload: ManualTestPayloadContext): Dict {
		return new ManualTestAnalyticsEventPayloadBuilder()
			.withAppName(payload.appName)
			.withBranchName(payload.branchName)
			.withTestStageName(payload.testStageName)
			.build();
	}
}
