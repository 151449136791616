import {
	ChangeDetectionStrategy,
	Component,
	inject,
	input,
	OnInit,
	signal
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { NavigationEnd, Params, Router, RouterModule } from '@angular/router';
import { TagModule } from 'primeng/tag';

@Component({
	selector: 'sl-header-button',
	templateUrl: './header-button.component.html',
	styleUrl: './header-button.component.scss',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [TagModule, RouterModule, MatIcon],
})
export class HeaderButtonComponent implements OnInit {
	readonly routeSignal$ = input<string>(null, { alias: 'route' });
	readonly textSignal$ = input<string>(null, { alias: 'text' });
	readonly tagSignal$ = input<string>(null, { alias: 'tag' });
	readonly iconSignal$ = input<string>(null, { alias: 'icon' });
	readonly badgeSignal$ = input<string>(null, { alias: 'badge' });
	readonly expandableSignal$ = input<boolean>(null, { alias: 'expandable' });
	readonly queryParamsSignal$ = input<Params>(null, { alias: 'queryParams' });

	readonly isActiveRoute = signal(false);

	readonly #router = inject(Router);

	ngOnInit(): void {
		this.#setIsActiveRoute();
		this.#router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.#setIsActiveRoute();
			}
		});
	}

	#checkActiveRoute(routerLink?: string): boolean {
		if (!routerLink) {
			return false;
		}

		const urlTree = this.#router.createUrlTree([routerLink], {
			queryParams: this.queryParamsSignal$()
		});

		return this.#router.isActive(urlTree, {
			fragment: 'ignored',
			matrixParams: 'ignored',
			queryParams: 'subset',
			paths: 'subset'
		});
	}

	#setIsActiveRoute(): void {
		this.isActiveRoute.set(this.#checkActiveRoute(this.routeSignal$()));
	}
}
