import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { catchError, map, of, take } from 'rxjs';
import { AuthService } from 'src/app/features/auth/services/auth.service';

export const canActivateAccessTokenGuard: CanActivateFn = () => {
	const currentTime = new Date().getTime();
	const authService = inject(AuthService);
	const router = inject(Router);

	if (!localStorage.getItem('auth-token-expiry') || !(localStorage.getItem('access-token') || localStorage.getItem('auth-token')) || !(localStorage.getItem('provider') === 'sealights' || localStorage.getItem('refresh-token'))) {
		return router.createUrlTree(['/login']);
	}

	const expiryTime = new Date(localStorage.getItem('auth-token-expiry')).getTime();

	if (currentTime < expiryTime) {
		return of(true);
	} else {
		return authService.extendToken()
			.pipe(
				map(() => true),
				catchError(() => {
					void authService.logout(true).pipe(
						take(1)
					).subscribe();
					return of(false);
				})
			);
	}
};
