import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, ElementRef, inject, Input, OnInit, output, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Observable, tap } from 'rxjs';

import { noDuplicatesInList } from '@@core/validators/generic.validators';

import { FormState, TestListItem } from '../../models/manual-test.model';
import { ManualTestService } from '../../services/manual-test.service';

@Component({
	selector: 'sl-test-list-overlay',
	templateUrl: './test-list-overlay.component.html',
	styleUrl: './test-list-overlay.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, NgClass, MatIconModule, MatTooltipModule, FormsModule, ReactiveFormsModule, CdkScrollable, CdkDropList, NgFor, CdkDrag]
})
export class TestListOverlayComponent implements OnInit {

	@Input() testList: TestListItem[];
	@Input() fromRecommendedTests = false;
	@Input() isFinalList = false;
	readonly testListUpdated = output<TestListItem[]>();
	readonly loadMoreClicked = output();

	readonly dragDropListContainer = viewChild<ElementRef<HTMLDivElement>>('dragDropListContainer');

	formState$: Observable<FormState>;
	isAddTestField = false;
	isVisible = false;
	isFormSubmitted = false;
	newTestForm: UntypedFormGroup;
	canLoadMore = false;
	readonly #destroyRef = inject(DestroyRef);
	readonly #manualTestService = inject(ManualTestService);
	readonly #cdRef = inject(ChangeDetectorRef);

	get isTestListPartiallyIncluded(): boolean {
		return this.includedTestsLength < this.testList.length && this.includedTestsLength !== 0;
	}

	get includedTestsLength(): number {
		return this.testList.filter(test => !test.exclude).length;
	}

	ngOnInit(): void {
		this.newTestForm = new UntypedFormGroup({
			testName: new UntypedFormControl('', [Validators.required, noDuplicatesInList<TestListItem>(this.testList, 'name')])
		});
		this.formState$ = this.#manualTestService.testFormStateObservable$;
		this.formState$
			.pipe(
				takeUntilDestroyed(this.#destroyRef),
				tap(state => this.canLoadMore = !!state.recommendedTests?.paging?.nextPage))
			.subscribe();
	}

	drop(event: CdkDragDrop<string[]>): void {
		moveItemInArray(this.testList, event.previousIndex, event.currentIndex);
		this.testListUpdated.emit(this.testList);
	}

	toggleVisibility(): void {
		this.isVisible = !this.isVisible;
		this.#cdRef.detectChanges();
	}

	onShowAddTest(): void {
		if (!this.isFinalList) {
			this.isAddTestField = true;
		}
	}

	onAddTest(): void {
		if (this.newTestForm.valid) {
			const testNameFormControl = this.newTestForm.get('testName');

			this.testList.push({ name: testNameFormControl.value as string, exclude: false });
			this.newTestForm.reset();
			this.isFormSubmitted = false;
			setTimeout(() => this.dragDropListContainer()?.nativeElement.scrollTo(0, this.dragDropListContainer()?.nativeElement.scrollHeight), 0); // without setTimeout the new item height is not calculated.
		} else {
			this.isFormSubmitted = true;
		}
	}

	onToggleSpecificTestExclude(test: TestListItem): void {
		test.exclude = !test.exclude;
		this.testListUpdated.emit(this.testList);
	}

	onToggleAllTestsExclude(): void {
		this.setAllTestsExcludeState(this.includedTestsLength === this.testList.length);
	}

	setAllTestsExcludeState(isExcluded: boolean): void {
		this.testList.forEach(test => test.exclude = isExcluded);
	}

	onLoadMoreClick(): void {
		this.loadMoreClicked.emit();
	}
}
