import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class HotjarService {

	setHotjar(isOn: boolean): void {
		if (isOn) {
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.async = true;
			script.innerHTML = `(function(h,o,t,j,a,r){
							h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
							h._hjSettings={hjid:2692060,hjsv:6};
							a=o.getElementsByTagName('head')[0];
							r=o.createElement('script');r.async=1;
							r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
							a.appendChild(r);
						})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
			document.getElementsByTagName('head')[0].appendChild(script);
		}
	}
}
