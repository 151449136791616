import { APP_INITIALIZER, EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { CUSTOM_MATERIAL_ICON_OPTIONS, CustomMaterialIconsProviderOptions, defaultCustomMaterialIconsProviderOptions } from './custom-material-icons.model';
import { registerCustomMaterialIcons } from './custom-material-icons-loader';

export function provideCustomMaterialIcons(options: CustomMaterialIconsProviderOptions = defaultCustomMaterialIconsProviderOptions): EnvironmentProviders {
	return makeEnvironmentProviders([
		{
			provide: CUSTOM_MATERIAL_ICON_OPTIONS,
			useValue: options
		},
		{
			provide: APP_INITIALIZER,
			useFactory: registerCustomMaterialIcons,
			deps: [MatIconRegistry, DomSanitizer, CUSTOM_MATERIAL_ICON_OPTIONS],
			multi: true
		}
	]);
}
