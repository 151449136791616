import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { delay, from, map, Observable, of, switchMap } from 'rxjs';

import { PermissionsService } from '@@settings/services/permissions.service';
import { AuthStore } from '@@shared/stores/auth-store/stores/auth.store';

import { AuthService } from './auth.service';

export const canActivateAuthGuard = (route: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Observable<boolean | UrlTree> => {
	const url = state?.url;
	const permissionsService = inject(PermissionsService);
	const authStore = inject(AuthStore);
	const authService = inject(AuthService);
	const router = inject(Router);

	if (!url) {
		return redirectToLogin(url, authService, router);
	}

	if (authStore.isTokenExpired()) {
		return handleExtendTokenResponse(url, authService, router);
	} else {
		return permissionsService.getUserProfile()
			.pipe(
				map(() => true)
			);
	}
};

const redirectToLogin = (url: string, authService: AuthService, router: Router): Observable<boolean> =>
	authService.logout(false)
		.pipe(
			delay(1),
			switchMap(() => from(router.navigate(['/login'], { queryParams: { redirect: url } })))
		);

const handleExtendTokenResponse = (url: string, authService: AuthService, router: Router): Observable<boolean> =>
	authService.extendToken()
		.pipe(
			switchMap(response => {
				if (response?.success) {
					return of(true);
				} else {
					return redirectToLogin(url, authService, router);
				}
			})
		);
