import { InjectionToken } from '@angular/core';

export interface TokenInterceptorProviderOptions {
	debug: boolean;
}

export const defaultTokenInterceptorProviderOptions: TokenInterceptorProviderOptions = {
	debug: false
};

export const TOKEN_INTERCEPTOR_OPTIONS = new InjectionToken<TokenInterceptorProviderOptions>(
	'TOKEN_INTERCEPTOR_OPTIONS',
	{ factory: (): TokenInterceptorProviderOptions => defaultTokenInterceptorProviderOptions }
);
