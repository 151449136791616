import { Component, inject } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { ChangePasswordComponent } from '@@shared/change-password/components/change-password/change-password.component';

@Component({
	selector: 'sl-change-password-dialog',
	templateUrl: './change-password-dialog.component.html',
	styleUrl: './change-password-dialog.component.scss',
	standalone: true,
	imports: [
		MatDialogModule,
		ChangePasswordComponent
	]
})
export class ChangePasswordDialogComponent {
	readonly #dialogRef = inject(MatDialogRef) as MatDialogRef<ChangePasswordDialogComponent>;

	onClose(): void {
		this.#dialogRef.close();
	}
}
