import { inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Userpilot } from 'userpilot';

import { UserPilotExperience } from '@@core/models/userpilot.model';
import { APP_CONFIG } from '@@shared/providers/application-config-provider/application-config-provider.model';


@Injectable({
	providedIn: 'root'
})
export class UserPilotService {

	readonly isProductTourVisible$ = new Subject<boolean>();
	#userPilotTriggerId?: string;
	readonly #applicationConfig = inject(APP_CONFIG);

	init(isOn: boolean): void {
		if (!isOn) {
			return;
		}

		const { userPilot } = this.#applicationConfig;
		if (userPilot?.token) {
			Userpilot.initialize(userPilot.token);
		}
	}

	triggerRouteExperience(): void {
		Userpilot.trigger(this.#userPilotTriggerId);
	}

	setCurrentExperience(experience: UserPilotExperience | null, isFeatureOn: boolean): void {
		const userPilot = this.#applicationConfig?.userPilot;
		const experienceList = userPilot?.experiences || {};
		this.#userPilotTriggerId = experienceList[experience] || null;
		this.isProductTourVisible$.next(this.#userPilotTriggerId !== null && isFeatureOn);
	}
}
