import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LoginResult } from '@@core/models/auth/auth-model';
import { APP_CONFIG } from '@@shared/providers/application-config-provider/application-config-provider.model';

@Injectable()
export class ChangePasswordService {
	readonly #apiBaseUrl = inject(APP_CONFIG)?.uri?.apiBaseUrl;
	readonly #http = inject(HttpClient);

	changePassword(oldPassword: string, newPassword: string): Observable<LoginResult> {
		const url = `${this.#apiBaseUrl}v2/auth/password`;
		const accessToken = localStorage.getItem('access-token');
		const authToken = localStorage.getItem('auth-token');
		const headers = {
			headers: new HttpHeaders({
				'x-sl-access-token': `${accessToken}`,
				Authorization: `Bearer ${authToken}`
			})
		};
		const body = {
			password: {
				new: newPassword,
				old: oldPassword
			}
		};

		return this.#http.put<LoginResult>(url, body, headers);
	}
}
