import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { omit } from 'lodash-es';
import { BehaviorSubject, concatMap, map, Observable, of, tap } from 'rxjs';
import { TiaRecommendedTest, TiaRecommendedTestsRequestParams } from 'src/app/features/tia/models/tia.model';

import { ApiResponse, PagedResponse, PagedResponseWrapper } from '@@core/models/api.model';
import { ApiService } from '@@shared/common';
import { APP_CONFIG } from '@@shared/providers/application-config-provider/application-config-provider.model';

import {
	Application, Branch, Build, BuildSession, ExecutionStep, FormState, Lab, LabIdsRequestParams, LabListItem,
	RunningTest, TEST_EVENT_FRAMEWORK, TEST_PERSISTENT_STATE_KEY, TestCounters, TestEventsWrapper,
	TestPersistentState, TestResult, TestsEvent, TiaContext
} from '../models/manual-test.model';

@Injectable({
	providedIn: 'root'
})
export class ManualTestService {
	testFormStateObservable$: Observable<FormState>;
	isActiveObservable$: Observable<boolean>;
	runningTestObservable$: Observable<RunningTest>;
	isOverlayShownObservable$: Observable<boolean>;
	tiaContextObservable$: Observable<TiaContext>;

	readonly #apiBaseUrl: string = inject(APP_CONFIG)?.uri?.apiBaseUrl;

	readonly #testFormState$ = new BehaviorSubject<FormState>({});
	readonly #isActive$ = new BehaviorSubject<boolean>(false);
	readonly #runningTest$ = new BehaviorSubject<RunningTest>(null);
	readonly #isOverlayShown$ = new BehaviorSubject<boolean>(false);
	readonly #tiaContext$ = new BehaviorSubject<TiaContext>(null);
	readonly #apiService = inject(ApiService);
	readonly #http = inject(HttpClient);

	constructor() {
		this.init();
	}

	set showOverlay(value: boolean) {
		this.#isOverlayShown$.next(value);
	}

	set runningTest(value: Partial<RunningTest>) {
		this.#runningTest$.next({ ...this.#runningTest$.value, ...value });
		this.#isActive$.next(this.#runningTest$.value.active);
		this.savePersistentState({
			runningTest: this.#runningTest$.value
		});
	}

	set tiaContext(context: TiaContext) {
		this.#tiaContext$.next(context);
	}

	init(): void {
		this.testFormStateObservable$ = this.#testFormState$.asObservable();
		this.isActiveObservable$ = this.#isActive$.asObservable();
		this.runningTestObservable$ = this.#runningTest$.asObservable();
		this.isOverlayShownObservable$ = this.#isOverlayShown$.asObservable();
		this.tiaContextObservable$ = this.#tiaContext$.asObservable();

		const testPersistentState = this.getPersistentTest();

		this.saveFormState(testPersistentState?.formState ?? {});
		if (testPersistentState?.runningTest?.active) {
			this.runningTest = testPersistentState.runningTest;
		}
		this.#runningTest$.next(testPersistentState?.runningTest);
	}

	getApps(): Observable<PagedResponseWrapper<Application, PagedResponse<Application>>> {
		const url = `${this.#apiBaseUrl}v3/apps?showHidden=false`;

		return this.#http.get<{ data: string[] }>(url)
			.pipe(
				map(response => ({
					data: {
						list: response.data.sort((prev, curr) => prev.toLowerCase().localeCompare(curr.toLowerCase())).map(app => ({ name: app } as Application)),
						next: 0,
						total: response.data.length
					}
				}) as PagedResponseWrapper<Application, PagedResponse<Application>>)
			);
	}

	getBranches(appName: string): Observable<PagedResponseWrapper<Branch, PagedResponse<Branch>>> {
		const url = `${this.#apiBaseUrl}v3/branches`;
		const queryParams = new HttpParams({
			fromObject: {
				appName
			}
		});

		return this.#http.get<{ data: string[] }>(url, { params: queryParams })
			.pipe(
				map(response => ({
					data: {
						list: response.data.sort((prev, curr) => prev.toLowerCase().localeCompare(curr.toLowerCase())).map(branch => ({
							name: branch,
							branchName: branch
						})),
						next: 0,
						total: response.data.length
					}
				}) as PagedResponseWrapper<Branch, PagedResponse<Branch>>)
			);
	}

	getLatestBuild(appName: string, branchName: string): Observable<{ build: Build }[]> {
		const url = `${this.#apiBaseUrl}v2/builds/filter`;	// TODO: this endpoint is required for thr bsid included in v1/build-history (as implemened in chrome extension)
		const body = {
			appBranches: [
				{
					appName,
					branchNames: [branchName]
				}
			]
		};

		return this.#http.post<{ build: Build }[]>(url, body);
	}

	getBuilds(appName: string, branchName: string): Observable<PagedResponseWrapper<Build, PagedResponse<Build>>> {
		const latestBuild = (build: Build): Build => ({
			name: build.name,
			buildName: build.name,
			bsid: build.bsid,
			generated: build.generated
		});

		return this.getLatestBuild(appName, branchName)
			.pipe(
				concatMap(response => {
					if (response.length) {
						const bsid = response[0].build.bsid;
						const url = `${this.#apiBaseUrl}v1/build-history/${bsid}`;	// TODO: ask BE team to provide a new endpoint to get builds per app/branch for a specfied time or build range

						return this.#http.get<Build[]>(url)
							.pipe(
								tap(builds => {
									/**
									 * if the active build is the only build then getHistoryBuildsByBsid() returns an empty list (BE behavior).
									 * In this case we populate the build history list with the active build
									 */
									if (!builds.length) {
										builds.push(latestBuild(response[0].build));
									}

									return builds;
								})
							);
					} else {
						return of([] as Build[]);
					}
				}),
				map(response => {
					response ??= [];

					return {
						data: {
							previous: 0,
							next: 0,
							total: response.length,
							list: response.map(build => ({
								name: build.buildName,
								buildName: build.buildName,
								bsid: build.bsid,
								generated: build.generated
							}))
						}
					} as PagedResponseWrapper<Build, PagedResponse<Build>>;
				}));
	}

	getBuildSession(appName: string, branchName: string, buildName: string): Observable<BuildSession> {
		const url = `${this.#apiBaseUrl}v2/agents/buildsession?appName=${encodeURIComponent(appName)}&branchName=${encodeURIComponent(branchName)}&buildName=${encodeURIComponent(buildName)}`;

		return this.#http.get<ApiResponse<BuildSession>>(url)
			.pipe(
				map(response => response.data[0] as BuildSession)
			);
	}

	getLabBuildSession(labId: string): Observable<BuildSession> {
		const url = `${this.#apiBaseUrl}v1/lab-ids/${encodeURIComponent(labId)}/build-sessions/active?agentId=webapp-${crypto.randomUUID()}`;

		return this.#http.get<BuildSession>(url);
	}

	getIntegrationBuilds(): Observable<Lab[]> {
		const url = `${this.#apiBaseUrl}v1/lab-ids?type=integration&live=true`;

		return this.#http.get<Lab[]>(url)
			.pipe(
				map(response => response.map(lab => ({ ...lab, name: lab.labId })))
			);
	}

	getSealightsGeneratedBuilds(): Observable<Lab[]> {
		const url = `${this.#apiBaseUrl}v1/lab-ids?includeSealightsGeneratedGuids=true&live=true`;

		return this.#http.get<Lab[]>(url)
			.pipe(
				map(response => response.map(lab => ({ ...lab, name: lab.labId })))
			);
	}

	getLabIdsByAppBranch(params: LabIdsRequestParams): Observable<PagedResponseWrapper<LabListItem, PagedResponse<LabListItem>>> {
		let queryParams = this.#apiService.createPagedApiHttpParams(params);

		queryParams = queryParams
			.append('appName', params.appName)
			.append('branchName', params.branchName)
			.append('buildName', params.buildName);

		const url = `${this.#apiBaseUrl}v2/lab-ids?${queryParams.toString()}`;

		return this.#http.get<PagedResponseWrapper<LabListItem, PagedResponse<LabListItem>>>(url);
	}

	postTestEvents(testEventsWrapper: TestEventsWrapper): Observable<void> {
		const url = `${this.#apiBaseUrl}v2/agents/events`;

		return this.#http.post<void>(url, testEventsWrapper);
	}

	getRecommendedTests(params: TiaRecommendedTestsRequestParams): Observable<PagedResponseWrapper<TiaRecommendedTest, PagedResponse<TiaRecommendedTest>>> {
		const bsid = encodeURIComponent(params.bsid);
		let httpParams = this.#apiService.createPagedApiHttpParams(params)
			.append('appName', params.appName)
			.append('testStage', params.testStage);

		if (params.reason && params.reason !== 'all') {
			httpParams = httpParams.append('reason', params.reason);
		}

		const url = `${this.#apiBaseUrl}v2/tia/build/${bsid}/recommendations`;

		return this.#http.get<PagedResponseWrapper<TiaRecommendedTest, PagedResponse<TiaRecommendedTest>>>(url, { params: httpParams });
	}

	startTest(state: FormState): Observable<void> {
		const buildSession = state.lab
			? this.getBuildSession(state.labContext.appName, state.labContext.branchName, state.labContext.buildName)
			: this.getBuildSession(state.app.name, state.branch.name, state.build.name);

		return buildSession
			.pipe(
				concatMap(response => {
					const executionId = crypto.randomUUID();
					const timestamp = Date.now();
					const execStartTestCounters = this.getTestCounters(ExecutionStep.ExecStart);
					const testStartTestCounters = this.getTestCounters(ExecutionStep.TestStart);
					const testEvents = [
						{
							executionId,
							timestamp,
							framework: TEST_EVENT_FRAMEWORK,
							type: 'executionIdStarted',
							meta: {
								counters: execStartTestCounters
							}
						},
						{
							executionId,
							timestamp,
							framework: TEST_EVENT_FRAMEWORK,
							type: 'testStart',
							meta: {
								counters: testStartTestCounters
							},
							testPath: [this.#testFormState$.value.test],
							testName: this.#testFormState$.value.test
						}
					] as TestsEvent[];
					const testEventsWrapper = {
						customerId: response.customerId,
						appName: response.appName,
						branch: response.branchName,
						build: response.buildName,
						executionId,
						environment: {
							labId: state.lab?.labId ?? state.customLabId ?? response.buildSessionId,
							testStage: state.testStage
						},
						testSelectionStatus: 'web-app',
						events: testEvents
					} as TestEventsWrapper;

					return this.postTestEvents(testEventsWrapper)
						.pipe(
							tap(() => {
								this.savePersistentState({
									formState: state,
									testEventsWrapper: omit(testEventsWrapper, 'events'),
									runningTest: {
										totalPauseTime: 0,
										pauseStartTime: null,
										active: true,
										duration: 0,
										paused: false,
										ended: false,
										startTime: new Date().getTime()
									}
								});
							})
						);
				})
			);
	}

	endTest(testResult: TestResult): Observable<void> {
		const testPersistentState = this.getPersistentTest();
		const testEventsWrapper = testPersistentState.testEventsWrapper;
		const timestamp = Date.now();
		const testEndTestCounters = this.getTestCounters(ExecutionStep.TestEnd);
		const execEndTestCounters = this.getTestCounters(ExecutionStep.ExecEnd);
		const testEvents = [
			{
				executionId: testEventsWrapper.executionId,
				timestamp,
				framework: TEST_EVENT_FRAMEWORK,
				type: 'testEnd',
				meta: {
					counters: testEndTestCounters
				},
				testPath: [this.#testFormState$.value.test],
				testName: this.#testFormState$.value.test,
				duration: this.#runningTest$.value.duration,
				result: testResult
			},
			{
				executionId: testEventsWrapper.executionId,
				timestamp,
				framework: TEST_EVENT_FRAMEWORK,
				type: 'executionIdEnded',
				meta: {
					counters: execEndTestCounters
				}
			}
		] as TestsEvent[];
		testEventsWrapper.events = testEvents;

		return this.postTestEvents(testEventsWrapper as TestEventsWrapper)
			.pipe(
				tap(() => this.resetRunningTest())
			);
	}

	cancelTest(): Observable<void> {
		return this.endTest(TestResult.Cancelled);
	}

	getTestCounters(executionStep: ExecutionStep): TestCounters {
		const execStart = executionStep === ExecutionStep.ExecStart ? 1 : 0;
		const testStart = executionStep === ExecutionStep.TestStart ? 1 : 0;
		const testEnd = executionStep === ExecutionStep.TestEnd ? 1 : 0;
		const execEnd = executionStep === ExecutionStep.ExecEnd ? 1 : 0;

		return {
			execStart,
			testStart,
			testEnd,
			execEnd
		} as TestCounters;
	}

	resetTiaContext(): void {
		this.#tiaContext$.next(null);
	}

	resetFormState(): void {
		this.#testFormState$.next({});
	}

	saveFormState(state: Partial<FormState>): FormState {
		this.#testFormState$.next({ ...this.#testFormState$.value, ...state });
		this.savePersistentState({ formState: state });
		return this.#testFormState$.value;
	}

	getPersistentTest(): Partial<TestPersistentState> {
		try {
			return (JSON.parse(localStorage.getItem(TEST_PERSISTENT_STATE_KEY)) ?? null) as TestPersistentState;
		} catch (e: any) {
			return {};
		}
	}

	savePersistentState(state: Partial<TestPersistentState>): void {
		const testPersistentState = this.getPersistentTest();

		localStorage.setItem(TEST_PERSISTENT_STATE_KEY, JSON.stringify({ ...testPersistentState, ...state }));
	}

	resetRunningTest(): void {
		this.savePersistentState({
			runningTest: {
				active: false,
				duration: 0,
				paused: false,
				ended: true,
				totalPauseTime: 0,
				pauseStartTime: null,
				startTime: new Date().getTime()
			}
		});
	}
}
