import { inject, Injectable } from '@angular/core';

import { AnalyticsPayloadContextProvider, ClickExternalLinkPayloadContext, TrackActionType } from '../../models/analytics/header-analytics.model';
import { AnalyticsService } from './analytics.service';
import { HeaderAnalyticsEventPayloadBuilder } from './header-analytics-event-payload.builder';

@Injectable({
	providedIn: 'root'
})
export class HeaderAnalyticsService {
	readonly #analyticsService = inject(AnalyticsService);

	trackClickExternalLink(payloadProvider: AnalyticsPayloadContextProvider<ClickExternalLinkPayloadContext>): void {
		this.#analyticsService.trackSafe(TrackActionType.ClickExternalLink,
			(context) => new HeaderAnalyticsEventPayloadBuilder()
				.withExternalLink(context.name)
				.build(),
			payloadProvider
		);
	}

	trackOnClickInfoMenu(payload: any): void {
		this.#analyticsService.trackSafe<any>(TrackActionType.ClickInfoMenu, () => payload);
	}

	trackOnClickSealightsApiReferencePage(payload: any): void {
		this.#analyticsService.trackSafe<any>(TrackActionType.ClickSealightsApiReferencePage, () => payload);
	}

	trackOnClickWhatIsNewPage(payload: any): void {
		this.#analyticsService.trackSafe<any>(TrackActionType.ClickWhatIsNewPage, () => payload);
	}
	trackOnClickAgentReleaseNotes(payload: any): void {
		this.#analyticsService.trackSafe<any>(TrackActionType.ClickAgentReleaseNotesPage, () => payload);
	}

	trackOnClickSealightsLearningCenterPage(payload: any): void {
		this.#analyticsService.trackSafe<any>(TrackActionType.ClickSealightsLearningCenterPage, () => payload);
	}

	trackOnClickSealightsDocumentationPage(payload: any): void {
		this.#analyticsService.trackSafe<any>(TrackActionType.ClickSealightsDocumentationPage, () => payload);
	}

	trackOnClickSupportPortalPage(payload: any): void {
		this.#analyticsService.trackSafe<any>(TrackActionType.ClickSupportPortalPage, () => payload);
	}

	trackOnClickProductTourPage(payload: any): void {
		this.#analyticsService.trackSafe<any>(TrackActionType.ClickProductTourPage, () => payload);
	}

	trackOnClickPrivacyPolicyPage(payload: any): void {
		this.#analyticsService.trackSafe<any>(TrackActionType.ClickPrivacyPolicyPage, () => payload);
	}

	trackOnClickTermsOfUsePage(payload: any): void {
		this.#analyticsService.trackSafe<any>(TrackActionType.ClickTermsOfUsePage, () => payload);
	}

	trackNewManualTest(payloadProvider: AnalyticsPayloadContextProvider<void>): void {
		this.#analyticsService.trackSafe(
			TrackActionType.ClickNewManualTest,
			() => new HeaderAnalyticsEventPayloadBuilder()
				.build(),
			payloadProvider
		);
	}
}
