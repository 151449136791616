<section class="change-password-wrapper">
	<form class="sl-flexbox-column sl-flexbox-justify-space-around sl-flexbox-align-center" [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
		@if (!changePasswordStoreSignal$().successMessage) {
			<div class="change-password-fields sl-flexbox-column sl-flexbox-justify-space-around sl-flexbox-align-center">
				<mat-form-field>
					<input matInput type="password" placeholder="Old Password" formControlName="oldPassword" autocomplete="current-password">
				</mat-form-field>

				<mat-form-field>
					<input matInput type="password" placeholder="New Password" formControlName="newPassword" autocomplete="new-password">
				</mat-form-field>

				<mat-form-field>
					<input matInput type="password" placeholder="Retype Password" formControlName="confirmPassword" autocomplete="new-password">
				</mat-form-field>
			</div>

			<div class="change-pass-validation-box">
				<div class="validation-box-title">Password must follow these conditions:</div>
				<section class="validation-box-rules">
					<ul class="sl-flexbox-column">
						@for (rule of validationRules; track rule) {
							<li class="policy-item"
								[class.error]="changePasswordForm.controls.newPassword.errors?.hasOwnProperty(rule.key)"
								[class.success]="!changePasswordForm.controls.newPassword.errors?.hasOwnProperty(rule.key) && !changePasswordForm.controls.newPassword.pristine">
								<span class="text">{{ rule.rule }}</span>
							</li>
						}
						@if (changePasswordForm.controls.newPassword.errors?.zxcvbn) {
							<li class="policy-item error">
								<span class="text">
									{{
											changePasswordForm.controls.newPassword.errors?.zxcvbn?.warning
										||	changePasswordForm.controls.newPassword.errors?.zxcvbn?.suggestions[0]
										||	'Password is in dictionaries'
									}}
								</span>
							</li>
						}
						@if (changePasswordForm.errors?.matchPasswords) {
							<li class="policy-item error">
								<span class="text">{{ changePasswordForm.errors.matchPasswords }}</span>
							</li>
						}
						@if (changePasswordStoreSignal$().wrongOldPassword) {
							<li class="policy-item error">
								<span class="text">Wrong old password</span>
							</li>
						}
						@if (changePasswordStoreSignal$().serverError) {
							<li class="policy-item error">
								<span class="text">Server error</span>
							</li>
						}
					</ul>
				</section>
			</div>

			<div class="submit-buttons">
				<p-button
					styleClass="sl-v1-button"
					size="large"
					severity="naked"
					label="Back"
					(onClick)="closeDialog()">
				</p-button>

				<p-button
					type="submit"
					styleClass="sl-v1-button"
					size="large"
					label="Next"
					[disabled]="!changePasswordForm.valid">
				</p-button>
			</div>
		}
		@if (changePasswordStoreSignal$().successMessage) {
			<div class="success-message">PASSWORD CHANGED SUCCESSFULLY</div>
		}
	</form>
</section>
