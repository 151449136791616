import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { selectLoginAsUserId } from '@@shared/stores/app-stores/global-store/selectors/global-store.selectors';

@Injectable()
export class ImpersonateInterceptor implements HttpInterceptor {
	readonly #impersonatedUserIdSignal$ = toSignal(inject(Store).select(selectLoginAsUserId));

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

		const url = new URL(request.url, window.location.origin);
		let _request = request;

		if (this.#impersonatedUserIdSignal$() && !url.pathname.startsWith('/assets/')) {
			let httpParams = request.params;
			httpParams = httpParams.append('impersonateUser', true).append('impersonateUserId', this.#impersonatedUserIdSignal$());
			_request = request.clone({ params: httpParams });
		}

		return next.handle(_request);
	}
}
