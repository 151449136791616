import { AsyncPipe, DatePipe, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectorRef, Component, DestroyRef, ElementRef, HostListener, inject, OnInit, TemplateRef, viewChild } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { AbstractControl, FormBuilder, FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { uniqBy } from 'lodash-es';
import { SharedModule } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { BehaviorSubject, concatMap, distinctUntilChanged, forkJoin, Observable, of, take, tap } from 'rxjs';

import { ConfirmationDialogService } from '@@core/services/confirmation-dialog.service';
import { FeatureService } from '@@core/services/features.service';
import { LDFeatureKey } from '@@core/services/launch-darkly.service';
import { ConfirmationDialogData } from '@@shared/dialog';
import { APP_CONFIG } from '@@shared/providers/application-config-provider/application-config-provider.model';
import { SelectConfig, SelectItem } from '@@shared/select';
import { SelectComponent } from '@@shared/select/components/select/select.component';
import { DEFAULT_STOPWATCH_TIME_STATE, StopwatchComponent, TimeState } from '@@shared/stopwatch';

import { TiaDetailsRequestManualTestParams, TiaRecommendedTestsRequestParams } from '../../../../features/tia/models/tia.model';
import { Application, Branch, Build, FormState, Lab, LabListItem, LOCKED_TEST_STAGE_NAME, RunningTest, TestContext, TestListItem, TestResult, TestSource, TiaContext } from '../../models/manual-test.model';
import { ManualTestPayloadContext } from '../../models/manual-test-analytics.model';
import { ManualTestAnalyticsService } from '../../services/analytics/manual-test-analytics.service';
import { ManualTestService } from '../../services/manual-test.service';
import { TestListOverlayComponent } from '../test-list-overlay/test-list-overlay.component';

@Component({
	selector: 'sl-manual-test-launcher',
	templateUrl: './manual-test-launcher.component.html',
	styleUrl: './manual-test-launcher.component.scss',
	standalone: true,
	imports: [
		MatButtonModule,
		DialogModule,
		SharedModule,
		NgIf,
		NgClass,
		StopwatchComponent,
		MatIconModule,
		FormsModule,
		ReactiveFormsModule,
		MatRadioModule,
		SelectComponent,
		DropdownModule,
		NgTemplateOutlet,
		MatTooltipModule,
		TestListOverlayComponent,
		AsyncPipe,
		DatePipe
	]
})
export class ManualTestLauncherComponent implements OnInit {
	readonly trigger = viewChild.required<MatButton>('trigger');
	readonly stopwatch = viewChild<StopwatchComponent>(StopwatchComponent);
	readonly buildItemTemplate = viewChild.required<TemplateRef<any>>('buildItemTemplate');
	readonly labIdItemTemplate = viewChild.required<TemplateRef<any>>('LabIdItemTemplate');
	readonly testListOverlay = viewChild<TestListOverlayComponent>('testListOverlay');
	readonly uploadFileInput = viewChild<ElementRef<HTMLInputElement>>('uploadFileInput');

	manualTestForm: UntypedFormGroup;
	stopWatchTimeState: TimeState;
	formState$: Observable<FormState>;
	formState: FormState;
	testContext = TestContext.ByAppBranchBuild;
	apps$ = new BehaviorSubject<Application[]>([]);
	branches$ = new BehaviorSubject<Branch[]>([]);
	builds$ = new BehaviorSubject<Build[]>([]);
	labIds$ = new BehaviorSubject<SelectItem[]>([]);
	labs$ = new BehaviorSubject<Lab[]>([]);
	isActive$: Observable<boolean>;
	isActive = false;
	TestResult = TestResult;
	runningTest$: Observable<RunningTest>;
	runningTest: RunningTest;
	requestingCancel = false;
	submitted = false;
	showMoreSubmitOptions = false;
	appSelectConfig = {
		scrollHeight: '200px',
		placeholder: 'Select app',
		appendTo: undefined
	} as SelectConfig;
	branchSelectConfig = {
		optionLabel: 'branchName',
		scrollHeight: '200px',
		placeholder: 'Select branch',
		appendTo: undefined
	} as SelectConfig;
	buildSelectConfig = {
		optionLabel: 'buildName',
		scrollHeight: '200px',
		placeholder: 'Select build',
		appendTo: undefined
	} as SelectConfig;
	labIdSelectConfig = {
		scrollHeight: '200px',
		placeholder: 'Select lab',
		appendTo: undefined
	} as SelectConfig;
	labSelectConfig = {
		scrollHeight: '200px',
		placeholder: 'Select lab',
		appendTo: undefined
	} as SelectConfig;
	typedLabIdSelected = false;
	readonly testStageNames$ = new BehaviorSubject<string[]>([]);
	loading = false;
	readonly TEXT_INPUT_VALID_REGEX = /\S+/i;
	tiaContext: TiaContext;
	testSource = 'single' as TestSource;
	visible = false;
	disableTestListOverlay = false;
	testListPaging = {
		limit: 50,
		nextPage: 1,
		total: 0
	};
	lockTestStageNameSignal$ = toSignal(inject(FeatureService).isFeatureOn(LDFeatureKey.LOCK_MANUAL_TEST_STAGE_NAME));
	readonly recommendedTestListFeatureActiveSignal$ = toSignal(inject(FeatureService).isFeatureOn(LDFeatureKey.RECOMMENDED_TEST_LIST));
	readonly #componentName = 'component-manual-test-launcher';	// a CSS class added to / removed from the <body> element when the component is shown/hidden
	readonly #destroyRef = inject(DestroyRef);
	readonly #names = inject(APP_CONFIG)?.manualTests?.testStage?.names;
	readonly #formBuilder = inject(FormBuilder);
	readonly #manualTestService = inject(ManualTestService);
	readonly #confirmationDialogService = inject(ConfirmationDialogService);
	readonly #manualTestAnalyticsService = inject(ManualTestAnalyticsService);
	readonly #cdRef = inject(ChangeDetectorRef);

	get isTestFromList(): boolean {
		return (this.manualTestForm.controls.testSource.value as TestSource === 'list');
	}

	get includedTests(): TestListItem[] {
		return this.formState.recommendedTests?.items?.filter(test => !test.exclude);
	}

	get currentTestIndex(): number {
		return this.includedTests?.findIndex((item) => item.name === this.formState.test);
	}

	get saveButtonCaption(): string {
		if (this.isTestFromList && this.formState.recommendedTests?.allTestsEnded) {
			return 'Done';
		}

		if (this.currentTestIndex > 0) {
			return 'Resume';
		}

		if (!this.isTestFromList || this.currentTestIndex < 1 || !this.formState.test) {
			return 'Start';
		}
	}

	get submitButtonCaption(): string {
		if (this.isTestFromList) {
			if (this.nextTestName) {
				return 'SUBMIT AND START NEXT TEST';
			} else {
				return 'SUBMIT AND DONE';
			}
		} else {
			return 'SUBMIT AND DONE';
		}
	}

	get nextTestName(): string {
		let nextTest: string = null;

		if (!!this.formState.recommendedTests?.items?.length && this.currentTestIndex + 1 < this.includedTests.length) {
			nextTest = this.includedTests[this.currentTestIndex + 1].name;
		}

		return nextTest;
	}

	@HostListener('window:beforeunload', ['$event'])
	beforeUnloadHandler(): void {
		if (!this.stopWatchTimeState.pauseStartTime) {
			this.stopWatchTimeState.pauseStartTime = new Date().getTime();
		}
		this.#manualTestService.runningTest = this.stopWatchTimeState;
	}


	ngOnInit(): void {
		this.testStageNames$.next(this.#names?.length ? this.#names : [LOCKED_TEST_STAGE_NAME]);
		if (this.recommendedTestListFeatureActiveSignal$()) {
			this.testSource = 'list';
		}
		this.buildSelectConfig.itemTemplate = this.buildItemTemplate();
		this.labIdSelectConfig.itemTemplate = this.labIdItemTemplate();
		this.manualTestForm = this.#formBuilder.group({
			context: new UntypedFormControl(this.testContext),
			app: new UntypedFormControl(null),
			branch: new UntypedFormControl(null),
			build: new UntypedFormControl(null),
			labId: new UntypedFormControl(null),
			typedLabId: new UntypedFormControl('', [Validators.pattern(this.TEXT_INPUT_VALID_REGEX)]),
			lab: new UntypedFormControl(null),
			labContextAppName: new UntypedFormControl(''),
			labContextBranchName: new UntypedFormControl(''),
			labContextBuildName: new UntypedFormControl(''),
			labContextBsid: new UntypedFormControl(''),
			testStages: new UntypedFormControl(this.testStageNames$.value[0], [Validators.required]),
			testStage: new UntypedFormControl('', [Validators.required, Validators.pattern(this.TEXT_INPUT_VALID_REGEX)]),
			testSource: new UntypedFormControl(this.testSource),
			test: new UntypedFormControl('', [Validators.required, Validators.pattern(this.TEXT_INPUT_VALID_REGEX)])
		}, {
			validators: this.formValidator
		});
		if (this.lockTestStageNameSignal$()) {
			this.manualTestForm.get('testStage').removeValidators(Validators.required);
		} else {
			this.manualTestForm.get('testStages').removeValidators(Validators.required);
		}

		this.formState$ = this.#manualTestService.testFormStateObservable$;
		this.isActive$ = this.#manualTestService.isActiveObservable$;
		this.runningTest$ = this.#manualTestService.runningTestObservable$;

		this.#manualTestService.isOverlayShownObservable$
			.pipe(
				takeUntilDestroyed(this.#destroyRef),
				tap(shown => {
					if (shown) {
						(this.trigger()?._elementRef.nativeElement as HTMLButtonElement).click();
					}
				})
			)
			.subscribe();
		this.formState$
			.pipe(
				takeUntilDestroyed(this.#destroyRef),
				tap(state => this.formState = state))
			.subscribe();
		this.isActive$
			.pipe(
				takeUntilDestroyed(this.#destroyRef),
				distinctUntilChanged(),
				tap(isActive => {
					this.isActive = isActive;
					if (isActive) {
						this.manualTestForm.disable();
						(this.trigger()?._elementRef.nativeElement as HTMLButtonElement).click();
					} else {
						this.manualTestForm.enable();
					}
				}))
			.subscribe();
		this.runningTest$
			.pipe(
				takeUntilDestroyed(this.#destroyRef),
				tap(runningTest => {
					this.runningTest = runningTest;
					if (this.runningTest) {
						this.stopWatchTimeState = {
							pauseStartTime: runningTest.pauseStartTime,
							startTime: runningTest.startTime,
							totalPauseTime: runningTest.totalPauseTime
						};
					} else {
						this.stopWatchTimeState = DEFAULT_STOPWATCH_TIME_STATE();
					}
				}))
			.subscribe();
		this.#manualTestService.tiaContextObservable$
			.pipe(
				takeUntilDestroyed(this.#destroyRef),
				tap(context => this.tiaContext = context))
			.subscribe();
	}

	show(): void {
		document.body.classList.add(this.#componentName);
		this.submitted = false;
		if (!this.isActive) {
			this.#restoreForm();
			this.setTestNameValidators();
			this.manualTestForm.controls.test.reset();
			this.#getApps();
			this.#getLabs();

			const testListContext = this.formState;
			const tiaContext = this.tiaContext;
			const recommendedTests = this.formState.recommendedTests;
			const tiaContextEqualsTestListContext = (): boolean =>
				testListContext.app?.name === tiaContext.app.name && testListContext.branch?.name === tiaContext.branch.name && testListContext.build?.name === tiaContext.build.name;

			if (this.tiaContext && !tiaContextEqualsTestListContext() && recommendedTests?.items?.length && !recommendedTests?.allTestsEnded && this.currentTestIndex > 0) {
				const dialogConfig = {
					panelClass: ['confirmation-dialog', 'new-design'],
					hasBackdrop: true,
					disableClose: true,
					data: {
						title: 'Test list is running on another build',
						content: `Test list is running on the following app/branch/build:<p>${this.formState.app?.name}<br>${this.formState.branch?.name}<br>${this.formState.build?.name}<p>Please select this build in the dialog before proceeding with the tests`,
						showCancelButton: false,
						confirmButtonCaption: 'OK'
					}
				} as MatDialogConfig<ConfirmationDialogData>;

				this.#confirmationDialogService.openDialog(dialogConfig);
			}
		}
	}

	hide(): void {
		document.body.classList.remove(this.#componentName);
		if (!this.isActive) {
			this.#resetForm();
		}
		this.#manualTestService.showOverlay = false;
		this.#manualTestService.resetTiaContext();
	}

	appSelectionChanged(selectedApp: Application): void {
		this.branches$.next([]);
		this.builds$.next([]);
		if (selectedApp) {
			const controls = this.manualTestForm.controls;

			controls.branch.reset();
			controls.build.reset();
			this.#getBranches(selectedApp.name);
		}
	}

	branchSelectionChanged(selectedBranch: Branch): void {
		this.builds$.next([]);
		if (selectedBranch) {
			const controls = this.manualTestForm.controls;
			const selectedApp = controls.app.value as Application;

			controls.build.reset();
			if (selectedApp) {
				this.#getBuilds(selectedApp.name, selectedBranch.name);
			}
		}
	}

	buildSelectionChanged(selectedBuild: Build): void {
		this.labIds$.next([]);
		if (selectedBuild) {
			const controls = this.manualTestForm.controls;
			const selectedApp = controls.app.value as Application;
			const selectedBranch = controls.branch.value as Branch;

			controls.labId.reset();
			if (selectedApp && selectedBranch) {
				this.#getLabIds(selectedApp.name, selectedBranch.name, selectedBuild);
			}
		}
	}

	labIdSelectionChanged(selectedItem: SelectItem): void {
		if (selectedItem) {
			this.typedLabIdSelected = !selectedItem.labId;
			this.setTypedLabIdValidators();
		}
	}

	labSelectionChanged(selectedLab: Lab): void {
		if (selectedLab) {
			const controls = this.manualTestForm.controls;

			if (selectedLab.buildName) {
				controls.labContextAppName.setValue(selectedLab.appName, { emitEvent: false });
				controls.labContextBranchName.setValue(selectedLab.branchName, { emitEvent: false });
				controls.labContextBuildName.setValue(selectedLab.buildName, { emitEvent: false });
			} else if (selectedLab.mainBuild?.buildName) {
				controls.labContextAppName.setValue(selectedLab.mainBuild.appName, { emitEvent: false });
				controls.labContextBranchName.setValue(selectedLab.mainBuild.branchName, { emitEvent: false });
				controls.labContextBuildName.setValue(selectedLab.mainBuild.buildName, { emitEvent: false });
				controls.labContextBsid.setValue(selectedLab.mainBuild.buildSessionId, { emitEvent: false });
			} else {
				this.#manualTestService.getLabBuildSession(selectedLab.labId)
					.pipe(
						take(1),
						tap(response => {
							controls.labContextAppName.setValue(response?.appName, { emitEvent: false });
							controls.labContextBranchName.setValue(response?.branchName, { emitEvent: false });
							controls.labContextBuildName.setValue(response?.buildName, { emitEvent: false });
							controls.labContextBsid.setValue(response?.buildSessionId, { emitEvent: false });
						})
					)
					.subscribe();
			}
		}
	}

	setTypedLabIdValidators(): void {
		const control = this.manualTestForm.controls.typedLabId;

		if (this.typedLabIdSelected) {
			control.addValidators(Validators.required);
		} else {
			control.removeValidators(Validators.required);
		}

		control.updateValueAndValidity();
	}

	onChangeTestResult(testResult: TestResult): void {
		this.#manualTestService.runningTest = { testResult };
	}

	formValidator(formGroup: AbstractControl): ValidationErrors | null {
		const testContext = formGroup.get('context')?.value as TestContext;
		const testSource = formGroup.get('testSource')?.value as TestSource;
		const invalidRequired = { required: true };

		if (testSource === 'single' && !(formGroup.get('test')?.value as string)?.trim()) {
			return invalidRequired;
		}

		if (testContext === TestContext.ByAppBranchBuild) {
			const appBranchBuildValid = !!formGroup.get('app')?.value && !!formGroup.get('branch')?.value && !!formGroup.get('build')?.value;
			const labIdValid = !!formGroup.get('labId')?.value || !!formGroup.get('typedLabId')?.value;

			return appBranchBuildValid && labIdValid ? null : invalidRequired;
		} else {
			const labValid = !!formGroup.get('lab')?.value;
			const labContextValid = !!formGroup.get('labContextAppName')?.value && !!formGroup.get('labContextBranchName')?.value && !!formGroup.get('labContextBuildName')?.value;

			return labValid && labContextValid ? null : invalidRequired;
		}
	}

	onRequestCancel(): void {
		this.requestingCancel = true;
		this.showMoreSubmitOptions = false;
	}

	cancel(): void {
		this.#manualTestService.cancelTest()
			.pipe(
				take(1),
				tap(() => {
					this.#manualTestService.runningTest = { active: false, duration: 0, ended: false, paused: false };
					this.#restoreForm();
					this.manualTestForm.controls.test.reset();
					this.requestingCancel = false;
				})
			)
			.subscribe();
		this.#manualTestService.runningTest = { active: false, duration: 0, ended: false, paused: false }; // DELETEEEEEEEEEE


		if (this.nextTestName) {
			this.formState.test = this.nextTestName;
		} else if (this.isTestFromList) {
			this.formState.recommendedTests.allTestsEnded = true;
		}
		this.#trackClickCancel();
		this.showMoreSubmitOptions = false;
		this.#manualTestService.runningTest = { active: false, duration: 0, ended: true, paused: false, testResult: undefined };
		this.#manualTestService.saveFormState(this.formState);
		this.onViewTestList();
	}

	save(): void {
		if (this.isTestFromList) {
			if (this.formState.recommendedTests.allTestsEnded) {
				this.visible = false;
				this.onDeleteTestList();
				this.#manualTestService.saveFormState(this.formState);
				return;
			}

			if (!this.formState.test) {
				this.formState.test = this.includedTests[0].name;
				this.#manualTestService.saveFormState(this.formState);
			}
		}

		const controls = this.manualTestForm.controls;
		const newFormState = {
			testStage: this.lockTestStageNameSignal$() ? controls.testStages.value as string : controls.testStage.value as string,
			test: this.isTestFromList ? this.formState.test : (controls.test.value as string).trim()
		} as Partial<FormState>;

		if (this.testContext === TestContext.ByAppBranchBuild) {
			newFormState.app = controls.app.value as Application;
			newFormState.branch = controls.branch.value as Branch;
			newFormState.build = controls.build.value as Build;
			newFormState.lab = null;
			newFormState.customLabId = (!this.labIds$.value.length || this.typedLabIdSelected) ? controls.typedLabId.value as string : (controls.labId.value as LabListItem).labId;
		} else {
			newFormState.lab = controls.lab.value as Lab;
			newFormState.labContext = {
				appName: controls.labContextAppName.value as string,
				branchName: controls.labContextBranchName.value as string,
				buildName: controls.labContextBuildName.value as string
			};
		}

		this.#manualTestService.saveFormState(newFormState);
		this.#manualTestService.runningTest = { active: true, duration: 0, ended: false, paused: false, pauseStartTime: null, totalPauseTime: 0, startTime: new Date().getTime() };
		this.#manualTestService.startTest(this.formState)
			.pipe(
				take(1)
			)
			.subscribe();

		this.#trackClickStart();
	}

	onBackToSetupAfterFinished(): void {
		this.backToSetup();
		delete this.formState.test;
		this.#manualTestService.saveFormState(this.formState);
	}

	onBackToListAfterFinished(): void {
		this.backToSetup();
		this.testListOverlay()?.toggleVisibility();
	}

	backToSetup(): void {
		this.submitted = false;
		this.#trackClickStartNew();
		this.manualTestForm.get('test').setValue('');
	}

	onDoneLastStep(): void {
		this.visible = false;
		if (this.isTestFromList) {
			this.onDeleteTestList();
		}
	}

	startNewAfterCancel(): void {
		this.#trackClickStartNew();
	}

	start(): void {
		this.stopwatch()?.resume();
		this.#manualTestService.runningTest = { paused: false };
	}

	pause(): void {
		this.stopwatch()?.pause();
		this.#manualTestService.runningTest = { paused: true };
	}

	restart(): void {
		this.#manualTestService.cancelTest().pipe(take(1)).subscribe();
		this.#manualTestService.startTest(this.formState).pipe(take(1)).subscribe();
		this.stopwatch()?.restart();
		this.#trackClickRestart();
	}

	resume(): void {
		this.#manualTestService.runningTest = { ended: false };
	}

	end(): void {
		this.stopwatch()?.pause();
		this.#manualTestService.runningTest = { ended: true };
	}

	submit(startNextTest = true): void {
		this.#manualTestService.endTest(this.runningTest.testResult)
			.pipe(
				take(1),
				concatMap(() => {
					this.#manualTestService.runningTest = { active: false, duration: 0, ended: false, paused: false, testResult: undefined };
					this.manualTestForm.controls.test.reset();
					if (this.nextTestName) {
						this.formState.test = this.nextTestName;
						this.#manualTestService.runningTest = { testResult: undefined };
						this.#manualTestService.saveFormState(this.formState);
						if (startNextTest) {
							this.#manualTestService.runningTest = { active: true, duration: 0, ended: false, paused: false, testResult: undefined };
							return this.#manualTestService.startTest(this.formState)
								.pipe(
									take(1)
								);
						} else {
							this.#manualTestService.runningTest = { active: false, duration: 0, ended: true, paused: false };
						}
					} else {
						if (startNextTest) {
							this.submitted = true;
						}
						this.hide();
					}

					if (!startNextTest) {
						this.onViewTestList();
					}
					this.#restoreForm();
					return of(null);
				})
			)
			.subscribe();
		this.#trackClickSubmit();
		this.showMoreSubmitOptions = false;
		if (this.isTestFromList && !this.nextTestName) {
			this.formState.recommendedTests.allTestsEnded = true;
			this.#manualTestService.saveFormState(this.formState);
		}
	}

	onSubmitAndBackToList(): void {
		this.showMoreSubmitOptions = false;
		this.submit(false);
	}

	testDurationChange(duration: number): void {
		this.#manualTestService.runningTest = { duration };
	}

	stopwatchTimeStateChanged(timeState: TimeState): void {
		this.#manualTestService.runningTest = timeState;
	}

	testContextChange(event: MatRadioChange): void {
		this.testContext = event.value as TestContext;
	}

	testSourceChange(event: MatRadioChange): void {
		this.testSource = event.value as TestSource;
	}

	onUploadFile(files: FileList): void {
		if (files) {
			this.#readTestListFromFile(files[0]);
		}
	}

	onGetRecommendedTestsClick(): void {
		this.#getRecommendedTests();
	}

	onViewTestList(): void {
		this.testListOverlay()?.toggleVisibility();
	}

	onDeleteTestListClick(): void {
		const dialogConfig = {
			panelClass: ['confirmation-dialog', 'new-design'],
			hasBackdrop: true,
			disableClose: true,
			data: {
				title: 'Delete test list',
				content: 'Are you sure you want to delete the test list?',
				cancelButtonCaption: 'BACK',
				confirmButtonCaption: 'YES, DELETE LIST'
			}
		} as MatDialogConfig<ConfirmationDialogData>;
		const onDialogClose = (confirmed): void => {
			if (confirmed) {
				this.onDeleteTestList();
			}
		};

		this.#confirmationDialogService.openDialog(dialogConfig, onDialogClose);
	}

	onDeleteTestList(): void {
		const controls = this.manualTestForm.controls;

		this.formState.recommendedTests = {
			items: []
		};
		delete this.formState.test;
		this.#manualTestService.saveFormState(this.formState);
		controls.testSource.setValue('single' as TestSource);
		controls.test.setValue('');
		this.setTestNameValidators();
		this.#cdRef.detectChanges();
	}

	recommendedTestListUpdated(recommendedTests: TestListItem[]): void {
		this.formState.recommendedTests.items = recommendedTests;
		this.#manualTestService.saveFormState(this.formState);
	}

	setTestNameValidators(): void {
		if (this.isTestFromList) {
			this.manualTestForm.controls.test.removeValidators(Validators.required);
		} else {
			this.manualTestForm.controls.test.addValidators(Validators.required);
		}

		this.manualTestForm.controls.test.updateValueAndValidity();
	}

	getMoreRecommendedTests(): void {
		this.#getRecommendedTests(false);
	}

	#readTestListFromFile(file: File): void {
		const reader = new FileReader();

		reader.onload = (): void => {
			const content = reader.result;

			if (typeof content === 'string' || content instanceof String) {
				const rows = content.split(/[\n]+/).slice(1);				// Extract the date rows. In CSV \n is a new line. Row 0 is assumed the header row
				const testNames = rows
					.map(row => row.split(',')[0])							// Pluck the first column only
					.filter(testName => !!testName.trim());
				const testNamesWithoutControlChars = [] as string[];

				if (!testNames.length) {
					const dialogConfig = {
						panelClass: ['confirmation-dialog', 'new-design'],
						hasBackdrop: true,
						disableClose: true,
						data: {
							title: 'Empty file',
							content: 'File contains no tests',
							confirmButtonCaption: 'OK',
							showCancelButton: false
						}
					} as MatDialogConfig<ConfirmationDialogData>;

					this.#confirmationDialogService.openDialog(dialogConfig);
					this.uploadFileInput().nativeElement.value = '';
					return;
				}
				testNames.forEach(name => {
					while (name) {
						if (name.charCodeAt(name.length - 1) < 32) {
							name = name.substring(0, name.length - 1);
						} else {
							testNamesWithoutControlChars.push(name);
							break;
						}
					}
				});

				delete this.formState.test;
				this.formState.recommendedTests = {
					items: testNamesWithoutControlChars.map<TestListItem>(testName => ({ name: testName, exclude: false }))
				};

				this.formState.recommendedTests.fileName = file.name;
				this.#manualTestService.saveFormState(this.formState);
				this.setTestNameValidators();
				this.#cdRef.detectChanges();
				this.onViewTestList();
			}
		};
		reader.readAsText(file);
	}

	#getRecommendedTests(firstPage = true): void {
		this.#manualTestService.getRecommendedTests(this.#recommendedTestsContext())
			.pipe(
				take(1),
				tap(response => {
					if (response.data?.list?.length) {
						let items = response.data.list.map<TestListItem>(test => ({ name: test.testName, exclude: false }));

						if (!firstPage) {
							items = [...this.formState.recommendedTests.items, ...items];
						}
						this.formState.recommendedTests = {
							items,
							fileName: null,
							allTestsEnded: false,
							paging: {
								nextPage: response.data.next ? response.data.next / this.testListPaging.limit + 1 : undefined
							}
						};
						this.#manualTestService.saveFormState(this.formState);
						this.setTestNameValidators();
						if (firstPage) {
							this.#cdRef.detectChanges();
							this.onViewTestList();
						}
					} else {
						const dialogConfig = {
							panelClass: ['confirmation-dialog', 'new-design'],
							hasBackdrop: true,
							disableClose: true,
							data: {
								title: 'No recommendations found',
								content: '',
								confirmButtonCaption: 'OK',
								showCancelButton: false
							}
						} as MatDialogConfig<ConfirmationDialogData>;

						this.#confirmationDialogService.openDialog(dialogConfig);
					}
				})
			)
			.subscribe();
	}

	#recommendedTestsContext(): TiaRecommendedTestsRequestParams {
		const controls = this.manualTestForm.controls;
		const requestParams = {
			testStage: controls.testStage.value as string,
			page: this.formState.recommendedTests?.paging?.nextPage ?? 1
		} as TiaDetailsRequestManualTestParams;

		if (this.testContext === TestContext.ByAppBranchBuild) {
			requestParams.bsid = (controls.build.value as Build).bsid;
			requestParams.appName = (controls.app.value as Application).name;
		} else {
			requestParams.bsid = controls.labContextBsid.value as string;
			requestParams.appName = controls.labContextAppName.value as string;
		}

		return requestParams;
	}

	#setLoading(loading: boolean, context: string): void {
		this.loading = loading;
	}

	#getApps(): void {
		// this.loading = true;
		this.#setLoading(true, 'apps loading');
		this.#manualTestService.getApps()
			.pipe(
				take(1),
				tap(response => {
					const control = this.manualTestForm.controls.app;

					// this.loading = false;
					this.#setLoading(false, 'apps loaded');

					if (!control.value) {
						control.setValue(response.data.list?.[0]);
					}
					this.apps$.next(response.data.list);
				})
			)
			.subscribe();
	}

	#getBranches(appName: string): void {
		// this.loading = true;
		this.#setLoading(true, 'branches loading');
		this.#manualTestService.getBranches(appName)
			.pipe(
				take(1),
				tap(response => {
					const control = this.manualTestForm.controls.branch;

					// this.loading = false;
					this.#setLoading(false, 'branches loaded');

					if (!control.value) {
						control.setValue(response.data.list?.[0]);
					}
					this.branches$.next(response.data.list);
				})
			)
			.subscribe();
	}

	#getBuilds(appName: string, branchName: string): void {
		// this.loading = true;
		this.#setLoading(true, 'builds loading');
		this.#manualTestService.getBuilds(appName, branchName)
			.pipe(
				take(1),
				tap(response => {
					const control = this.manualTestForm.controls.build;

					// this.loading = false;
					this.#setLoading(false, 'builds loaded');

					if (!control.value) {
						control.setValue(response.data.list?.[0]);
					}
					this.builds$.next(response.data.list);
				})
			)
			.subscribe();
	}

	#getLabIds(appName: string, branchName: string, build: Build): void {
		// this.loading = true;
		this.#setLoading(true, 'lab IDs loading');
		this.#manualTestService.getLabIdsByAppBranch({ appName, branchName, buildName: build.buildName })
			.pipe(
				take(1),
				tap(response => {
					const control = this.manualTestForm.controls.labId;

					// this.loading = false;
					this.#setLoading(false, 'builds loaded');

					const responseWithoutBsid = response.data.list.filter(lab => lab.labId !== build.bsid);
					const labIds = [
						{ name: `[bsid] ${build.bsid}`, labId: build.bsid, labAlias: build.bsid, disabled: false },
						...responseWithoutBsid.map(lab => ({ name: lab.labAlias ?? lab.labId, labId: lab.labId, labAlias: lab.labAlias, disabled: false })),
						{ name: '', disabled: true },
						{ name: 'Provide another labID', disabled: false }
					] as SelectItem[];

					if (!control.value) {
						const selectedValue = labIds.find(item => this.labs$.value?.some(lab => lab.labId === item.name));

						control.setValue(selectedValue ?? labIds?.[0]);
					}
					this.labIds$.next(labIds);
				})
			)
			.subscribe();
	}

	#getLabs(): void {
		// this.loading = true;
		this.#setLoading(true, 'labs loading');

		const integrationBuilds = this.#manualTestService.getIntegrationBuilds()
			.pipe(
				take(1)
			);
		const slIntegrationBuilds = this.#manualTestService.getSealightsGeneratedBuilds()
			.pipe(
				take(1)
			);
		forkJoin([integrationBuilds, slIntegrationBuilds])
			.pipe(
				tap(labs => {
					const combinedLabs = uniqBy(labs.flat(), (lab: Lab) => lab.labId).sort((a, b) => new Date(b.timestamp).valueOf() - new Date(a.timestamp).valueOf());
					const control = this.manualTestForm.controls.lab;

					// this.loading = false;
					this.#setLoading(false, 'labs loaded');

					if (!control.value || !combinedLabs.find(lab => lab.labId === (control.value as Lab).labId)) {
						control.setValue(combinedLabs?.[0]);
					}
					this.labs$.next(combinedLabs);
				}))
			.subscribe();
	}

	#resetForm(): void {
		const controls = this.manualTestForm.controls;

		controls.app.reset();
		controls.branch.reset();
		controls.build.reset();
		controls.typedLabId.reset();
		controls.lab.reset();
		controls.testStage.reset();
		controls.test.reset();
	}

	#restoreForm(): void {
		const controls = this.manualTestForm.controls;

		if (this.tiaContext) {
			controls.app.setValue(this.tiaContext.app, { emitEvent: false });
			controls.branch.setValue(this.tiaContext.branch, { emitEvent: false });
			controls.build.setValue(this.tiaContext.build, { emitEvent: false });
		} else {
			controls.app.setValue(this.formState.app, { emitEvent: false });
			controls.branch.setValue(this.formState.branch, { emitEvent: false });
			controls.build.setValue(this.formState.build, { emitEvent: false });
		}
		controls.lab.setValue(this.formState.lab, { emitEvent: false });
		controls.testStages.setValue(this.testStageNames$.value.findIndex(name => name === this.formState.testStage) > -1 ? this.formState.testStage : this.testStageNames$.value[0]);
		controls.testStage.setValue(this.formState.testStage);
		controls.test.setValue(this.formState.test);

		controls.labContextAppName.disable();
		controls.labContextBranchName.disable();
		controls.labContextBuildName.disable();
	}

	#trackClickStart(): void {
		void this.#manualTestAnalyticsService.trackClickStart(() => this.#getAnalyticsPayloadContext());
	}

	#trackClickSubmit(): void {
		void this.#manualTestAnalyticsService.trackClickSubmit(() => this.#getAnalyticsPayloadContext());
	}

	#trackClickCancel(): void {
		void this.#manualTestAnalyticsService.trackClickCancel(() => this.#getAnalyticsPayloadContext());
	}

	#trackClickStartNew(): void {
		void this.#manualTestAnalyticsService.trackClickStartNew(() => this.#getAnalyticsPayloadContext());
	}

	#trackClickRestart(): void {
		void this.#manualTestAnalyticsService.trackClickRestart(() => this.#getAnalyticsPayloadContext());
	}

	#getAnalyticsPayloadContext(): ManualTestPayloadContext {
		const controls = this.manualTestForm.controls;

		return {
			appName: (controls.app.value as Application)?.name ?? this.formState.app.name,
			branchName: (controls.branch.value as Branch)?.name ?? this.formState.branch.name,
			testStageName: controls.testStage.value as string ?? this.formState.testStage
		};
	}
}
