export interface TimeState {
	pauseStartTime: number;
	startTime: number;
	totalPauseTime: number;
}

export const DEFAULT_STOPWATCH_TIME_STATE = (): TimeState => ({
	pauseStartTime: null,
	totalPauseTime: 0,
	startTime: new Date().getTime()
});

