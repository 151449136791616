import { inject, Injector, runInInjectionContext } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { concatMap, from, last, Observable, of, takeWhile } from 'rxjs';

export const orderedAsyncCanActivate = (
	guards: CanActivateFn[]
): CanActivateFn => (route, state) => {
	const injectionContext = inject(Injector);
	// Instantiate all guards.
	// Convert an array into an observable.
	return from(guards).pipe(
		// For each guard, fire canActivate and wait for it
		// to complete.
		concatMap((guard) => runInInjectionContext(injectionContext, () => {
			const guardResult = guard(route, state);
			if (guardResult instanceof Observable) {
				return guardResult;
			} else if (guardResult instanceof Promise) {
				return from(guardResult);
			} else {
				return of(guardResult);
			}
		})),
		// Don't execute the next guard if the current guard's
		// result is not true.
		takeWhile((value) => value === true, /* inclusive */ true),
		// Return the last guard's result.
		last()
	);
};
