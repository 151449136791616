import { inject, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable({
	providedIn: 'root'
})
export class SwUpdateService {
	readonly #updates = inject(SwUpdate);

	async init(): Promise<void> {
		if (this.#updates.isEnabled) {
			console.log('[Service Worker] Available on this browser');
			console.log('[Service Worker] Checking for updates...');
			if (await this.#updates.checkForUpdate()) {
				console.log('[Service Worker] New Updates available!');
				console.log('[Service Worker] Updating...');
				await this.#updates.activateUpdate();
				console.log('[Service Worker] Update completed');
				console.log('[Service Worker] Reloading page...');
				window.location.reload();
			} else {
				console.log('[Service Worker] No new updates available');
			}
		} else {
			console.warn('[Service Worker] Unavailable on this browser');
		}
	}
}
