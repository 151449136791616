import { GenericAnalyticsEventPayloadBuilder } from '@@core/services/analytics/generic-analytics-event-payload.builder';

// TODO current implementation looks like an anti-pattern, discuss reasoning or alternatives

export class ManualTestAnalyticsEventPayloadBuilder extends GenericAnalyticsEventPayloadBuilder {
	withAppName(name: string): this {
		this.payload.app_name = name;

		return this;
	}

	withBranchName(name: string): this {
		this.payload.branch_name = name;

		return this;
	}

	withTestStageName(name: string): this {
		this.payload.test_stage_name = name;

		return this;
	}
}
