import { ChangeDetectionStrategy, Component, DestroyRef, inject, input, OnChanges, OnInit, output, signal, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { interval, Subject, switchMap, takeWhile, tap } from 'rxjs';

import { TimeState } from './models/stopwatch.model';

@Component({
	selector: 'sl-stopwatch',
	templateUrl: './stopwatch.component.html',
	styleUrl: './stopwatch.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class StopwatchComponent implements OnInit, OnChanges {
	readonly startOnCreateSignal$ = input<boolean>(true, { alias: 'startOnCreate' });
	readonly timeStateSignal$ = input<TimeState>(null, { alias: 'timeState' });

	readonly valueChange = output<number>();
	readonly timeStateChanged = output<TimeState>();

	readonly localTimeStateSignal$ = signal<TimeState>(null);
	hours = 0;
	minutes = 0;
	seconds = 0;
	formattedValue = '';
	readonly startNewClock$ = new Subject<void>();
	#running = false;
	readonly #destroyRef = inject(DestroyRef);

	get isRunning(): boolean {
		return this.#running;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.timeStateSignal$) {
			this.localTimeStateSignal$.set(this.timeStateSignal$());
		}
	}

	ngOnInit(): void {
		if (this.localTimeStateSignal$().pauseStartTime) {
			this.#updateTimeState({ pauseStartTime: this.#getCurrentTime(), totalPauseTime: this.#calculateTotalPauseTime() });
		}

		this.startNewClock$
			.pipe(
				takeUntilDestroyed(this.#destroyRef),
				switchMap(() => interval(500)
					.pipe(
						takeWhile(() => this.#running),
						tap(() => {
							this.#onTimeChanged();
						})
					)
				))
			.subscribe();

		this.recalculate();
		this.#format();
		if (this.startOnCreateSignal$()) {
			this.start();
		} else {
			this.#running = false;
		}
	}

	start(): void {
		this.#running = true;
		if (this.localTimeStateSignal$().pauseStartTime) {
			this.#updateTimeState({ pauseStartTime: null, totalPauseTime: this.#calculateTotalPauseTime() });
		}
		this.startNewClock$.next();
	}

	resume(): void {
		this.#running = true;
		if (this.localTimeStateSignal$().pauseStartTime) {
			this.#updateTimeState({ pauseStartTime: null, totalPauseTime: this.#calculateTotalPauseTime() });
		}
		this.startNewClock$.next();
	}

	pause(): void {
		if (this.localTimeStateSignal$().pauseStartTime) {
			this.#updateTimeState({ totalPauseTime: this.#calculateTotalPauseTime() });
		}
		this.#running = false;
		this.#updateTimeState({ pauseStartTime: this.#getCurrentTime() });
		this.timeStateChanged.emit(this.localTimeStateSignal$());
	}

	restart(): void {
		this.#running = true;
		this.startNewClock$.next();
		this.#updateTimeState({ pauseStartTime: null, startTime: this.#getCurrentTime(), totalPauseTime: 0 });
		this.#onTimeChanged();
	}

	recalculate(): void {
		this.seconds = Math.floor(this.#calculateRunningTime() / 1000);
		this.minutes = Math.floor(this.seconds / 60);
		this.hours = Math.floor(this.minutes / 60);

		this.seconds %= 60;
		this.minutes %= 60;
		this.hours %= 24;
	}

	#format(): void {
		this.formattedValue = `${this.hours.toString().padStart(3, '')}:${this.minutes.toString().padStart(2, '0')}:${this.seconds.toString().padStart(2, '0')}`;
	}

	#onTimeChanged(): void {
		this.recalculate();
		this.#format();
		this.valueChange.emit(this.#calculateRunningTime());
	}

	#getCurrentTime(): number {
		return new Date().getTime();
	}

	#calculateRunningTime(): number {
		return this.#getCurrentTime() - this.localTimeStateSignal$().totalPauseTime - this.localTimeStateSignal$().startTime;
	}

	#calculateTotalPauseTime(): number {
		return this.localTimeStateSignal$().totalPauseTime += this.#getCurrentTime() - this.localTimeStateSignal$().pauseStartTime;
	}

	#updateTimeState(timeState: Partial<TimeState>): void {
		this.localTimeStateSignal$.set({ ...this.localTimeStateSignal$(), ...timeState });
		this.timeStateChanged.emit(this.localTimeStateSignal$());
	}
}
