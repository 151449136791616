import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import * as SearchActions from '../actions/search.actions';
import { SearchModel } from '../models/search.model';

export interface SearchState extends EntityState<SearchModel> { }

export const adapter: EntityAdapter<SearchModel> = createEntityAdapter<SearchModel>();

export const initialState: SearchState = adapter.getInitialState();

export const searchReducer = createReducer(
	initialState,
	on(SearchActions.updateSearchId, (state, { id, searchTerm }) => adapter.upsertOne({ id, searchTerm }, state)),
	on(SearchActions.removeSearchId, (state, { id }) => adapter.removeOne(id, state))
);
