import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { defaultTokenInterceptorProviderOptions, TOKEN_INTERCEPTOR_OPTIONS, TokenInterceptorProviderOptions } from './token-interceptor.model';
import { TokenInterceptor } from './token-interceptor.service';

export function provideTokenInterceptor(options: TokenInterceptorProviderOptions = defaultTokenInterceptorProviderOptions): EnvironmentProviders {
	return makeEnvironmentProviders([
		{
			provide: TOKEN_INTERCEPTOR_OPTIONS,
			useValue: options
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		}
	]);
}
