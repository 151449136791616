import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getState } from '@ngrx/signals';
import { Store } from '@ngrx/store';
import { throttle } from 'lodash-es';
import { catchError, EMPTY, from, map, Observable, of, switchMap, tap } from 'rxjs';

import {
	AuthProviderType,
	BaseAuthData,
	CognitoAuthData,
	IntegrationAuthData,
	LoginResult
} from '@@core/models/auth/auth-model';
import { PermissionsService } from '@@settings/services/permissions.service';
import { APP_CONFIG } from '@@shared/providers/application-config-provider/application-config-provider.model';
import * as GlobalStoreActions from '@@shared/stores/app-stores/global-store/actions/global-store.actions';
import { AuthProvider, AuthState } from '@@shared/stores/auth-store/models/auth-store.models';
import { AuthStore } from '@@shared/stores/auth-store/stores/auth.store';
import * as UserPreferencesActions from '@@shared/user-preferences/actions/user-preferences.actions';

const TOKEN_EXTEND_DEBOUNCE_TIME = 5 * 60 * 1000;

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	readonly extendTokenDebounceFunction = throttle(() => this.extendToken().subscribe(), TOKEN_EXTEND_DEBOUNCE_TIME, { leading: false, trailing: true });

	readonly #apiBaseUrl = inject(APP_CONFIG)?.uri?.apiBaseUrl;
	readonly #http = inject(HttpClient);
	readonly #router = inject(Router);
	readonly #store = inject(Store);
	readonly #authStore = inject(AuthStore);
	readonly #permissionService = inject(PermissionsService);

	// Logic of the result should be moved to the store methods
	logout(withRedirect = true): Observable<any> {
		const isSSOUserSignal$ = this.#authStore.isSSOUser;
		const tokenSignal$ = this.#authStore.token;

		if (isSSOUserSignal$()) {
			return this.logoutSSO(tokenSignal$())
				.pipe(
					tap(response => {
						this.clearUserDataAndStopExtendingToken();
						if (response.url) {
							window.location.href = response.url;
						} else {
							if (withRedirect) {
								return this.#navigateToLoginWithRedirect();
							}
						}
					}),
					catchError(error => {
						console.error('SSO logout failed');
						this.clearUserDataAndStopExtendingToken();
						if (withRedirect) {
							return this.#navigateToLoginWithRedirect();
						}
						return EMPTY;
					}),
				);
		} else {
			this.clearUserDataAndStopExtendingToken();
			if (withRedirect) {
				return this.#navigateToLoginWithRedirect();
			} else {
				return of(null);
			}
		}
	}

	// Really complex one, affecting multiple services.
	clearUserDataAndStopExtendingToken(): void {
		this.#authStore.reset();
		this.stopTokenExtendTimeout();
		this.logoutFromImpersonateUser();
		this.#store.dispatch(UserPreferencesActions.clearUserPreferences());
		this.#store.dispatch(UserPreferencesActions.clearUserPreferencesOptions());
	}

	logoutFromImpersonateUser(): void {
		this.#store.dispatch(GlobalStoreActions.globalStoreSetLoginAsUser(null));
	}

	extendTokenSSO(redirectUri?: string): Observable<LoginResult> {
		const userFullData = getState(this.#authStore);

		if (!userFullData.provider || userFullData.provider !== AuthProvider.Okta) {
			const message = { status: undefined };

			return of(this.#mapToLoginResult(null, false, message));
		}

		const url = `${this.#apiBaseUrl}v2/auth/sso/extend`;
		const body = {
			refresh_token: userFullData.refreshToken,
			id_token: userFullData.token,
			redirect_uri: redirectUri || window.location.href
		};
		const headers = new HttpHeaders();
		const refreshToken = localStorage.getItem('refresh-token');
		const accessToken = localStorage.getItem('access-token');

		headers.append('x-sl-refresh-token', `${refreshToken}`);
		headers.append('x-sl-access-token', `${accessToken}`);

		return this.#http.post<BaseAuthData>(url, body, { headers })
			.pipe(
				switchMap(response => {
					response.expiresTimestamp = response.expires as string;
					response.originalUser = { ...response.user };
					if (this.#getImpersonatedUserId()) {
						response.user = this.#authStore.user();
					}
					const integrations = localStorage.getItem('integrations');
					response.integrations = JSON.parse(integrations) as IntegrationAuthData;

					this.#authStore.save(response as Partial<AuthState>);
					return this.#permissionService.getUserProfile()
						.pipe(
							map(() => this.#mapToLoginResult(response, true, null))
						);
				}),
				catchError(error => {
					const data = this.#mapToLoginResult(null, false, error);
					this.#authStore.reset();

					return of(data);
				})
			);
	}

	extendToken(): Observable<LoginResult> {
		const provider = localStorage.getItem('provider') as AuthProviderType;
		const isSSOUserSignal$ = this.#authStore.isSSOUser;

		if (isSSOUserSignal$()) {
			let redirectUri;

			if (provider === 'okta') {
				redirectUri = location.origin;
			}

			return this.extendTokenSSO(redirectUri as string);
		}

		if (provider && provider === 'sealights') {
			return of(null);
		}

		const url = `${this.#apiBaseUrl}v2/auth/token/extend`;
		const refreshToken = localStorage.getItem('refresh-token');
		const accessToken = localStorage.getItem('access-token');
		const headers = {
			headers: new HttpHeaders({
				'x-sl-refresh-token': `${refreshToken}`,
				'x-sl-access-token': `${accessToken}`,
			})
		};
		const userData = this.#authStore.user();

		if (userData === null) {
			const error = {
				status: null,
				statusText: 'Email Record Not Found'
			};
			return of(this.#mapToLoginResult(null, false, error));
		}

		const body = {
			email: userData.email
		};

		return this.#http.post<CognitoAuthData>(url, body, headers)
			.pipe(
				switchMap((response) => {
					const integrations = localStorage.getItem('integrations');
					response.integrations = JSON.parse(integrations) as IntegrationAuthData;

					response.expiresTimestamp = new Date((response.expires as number) * 1000).toISOString();
					response.originalUser = { ...response.user };
					if (this.#getImpersonatedUserId()) {
						response.user = this.#authStore.user();
					}
					this.#authStore.save(response as AuthState);

					return this.#permissionService.getUserProfile()
						.pipe(
							map(() => this.#mapToLoginResult(response, true, null))
						);
				}),
				catchError(error => of(this.#mapToLoginResult(null, false, error)))
			);
	}

	loginWithToken(token: string): Observable<LoginResult> {
		localStorage.setItem('okta-sso-code', token);

		const url = `${this.#apiBaseUrl}v2/auth/sso/login`;

		return this.#http.post<BaseAuthData>(url, {
			code: token,
			redirect_uri: location.origin
		}).pipe(
			map(response => {
				response.expiresTimestamp = response.expires as string;
				this.#authStore.save(response as Partial<AuthState>);

				return this.#mapToLoginResult(response, true, null);
			}),
			catchError(error => of(this.#mapToLoginResult(null, false, error)))
		);
	}

	logoutSSO(token: string): Observable<{ url: string; message: string }> {
		const url = `${this.#apiBaseUrl}v2/auth/sso/logout`;

		return this.#http.post<{ url: string; message: string }>(url, {
			id_token: token,
			redirect_uri: location.origin
		});
	}

	startTokenExtendTimer(forceTokenExtend = false): any {
		if (!localStorage.getItem('auth-token-expiry')) {
			return;
		}

		const expires = new Date(localStorage.getItem('auth-token-expiry')).valueOf();

		if (!expires) {
			return;
		}

		if (forceTokenExtend) {
			this.extendTokenDebounceFunction();
			this.extendTokenDebounceFunction.flush();
		} else {
			this.extendTokenDebounceFunction();
		}
	}

	stopTokenExtendTimeout(): void {
		if (this.extendTokenDebounceFunction) {
			this.extendTokenDebounceFunction.cancel();
		}
	}

	#mapToLoginResult(userData: BaseAuthData, success: boolean, message: any): LoginResult {
		return {
			success,
			userData,
			message
		};
	}

	#navigateToLogin(): Promise<boolean> {
		return this.#router.navigateByUrl('/login');
	}

	#navigateToLoginWithRedirect(): Observable<any> {
		return from(this.#navigateToLogin()
			.then(() => location.reload())
			.catch(() => void 0));
	}

	#getImpersonatedUserId(): string {
		return localStorage.getItem('impersonatedUserId');
	}
}
