import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const noDuplicatesInList = <T>(list: T[], label?: keyof T): ValidatorFn => (formControl: FormControl): ValidationErrors | null => {
	let response: { [key: string]: any } = null;
	let flatList: string[];

	if (label) { 															// label is provided when list is an array of objects
		flatList = list.map(obj => obj[label] as unknown as string);
	} else {																// label is NOT provided when list is an array of strings
		flatList = list as unknown as string[];
	}

	if (flatList.includes(formControl.value as string)) {
		response = {
			duplicateEntries: 'Entry already exists'
		};
	}

	return response;
};
