<div class="header">
	@if (showDataProgressSignal$() && (isBusy$ | async)) {
		<mat-progress-bar
			class="data-progress-bar"
			mode="query"
			color="accent">
		</mat-progress-bar>
	}
	<mat-toolbar
		color="accent"
		class="sl-flexbox-row sl-flexbox-justify-space-between sl-flexbox-align-center">
		<div class="header-logo-wrap">
			<a routerLink="/dashboard/v1">
				<div class="header-logo-img"></div>
			</a>
		</div>
		@if (!isSsoUserSignal$() && ssoMessageSignal$()) {
			<div class="header-message">
				<div class="message-content"
					[innerHtml]="ssoMessageSignal$()">
				</div>
			</div>
		}
		<div class="header-menu">
			<div class="header-links">
				@if (customTabsSignal$() && isCustomTabsFeatureActiveSignal$()) {
					@for (tab of customTabsSignal$(); track tab.url) {
						<sl-header-button
							route="/custom-tab"
							[text]="tab.name"
							[queryParams]="{
								url: tab.url,
								name: tab.name,
								type: 'iframe'
							}">
						</sl-header-button>
					}
				}
				@if (dashboardFeatureActiveSignal$()) {
					<sl-header-button
						text="Dashboard"
						route="/dashboard/v1"
						tag="Deprecated">
					</sl-header-button>
				}
				@if (coverageDashboardFeatureActiveSignal$()) {
					<sl-header-button
						text="Coverage Dashboard"
						route="/dashboard/v2">
					</sl-header-button>
				}
				<sl-header-button
					text="Quality Analytics"
					route="/quality-analytics">
				</sl-header-button>
				<sl-header-button
					text="TGA Report"
					route="/tga">
				</sl-header-button>
				<sl-header-button
					[matMenuTriggerFor]="testOptimizations"
					text="Test Optimization"
					route="/tia"
					[expandable]="true">
				</sl-header-button>
				<mat-menu
					#testOptimizations="matMenu"
					#testOptimizationsRLA="routerLinkActive"
					class="header-dropdown-menu"
					routerLinkActive>
					<a
						mat-menu-item
						[disableRipple]="true"
						routerLink="/tia/v3"
						[routerLinkActiveOptions]="{ exact: true }"
						routerLinkActive="active">
						<div>Summary</div>
					</a>
					<a
						mat-menu-item
						[disableRipple]="true"
						routerLink="/tia/v3/details"
						routerLinkActive="active">
						<div class="sl-flexbox-row sl-flexbox-align-center sl-flexbox-gap-8">
							<div>Build Breakdown</div>
							<p-tag
								value="New"
								severity="warning"
								styleClass="sl-v1-tag sl-v1-tag-xs sl-v1-tag-outlined"
								[rounded]="true"></p-tag>
						</div>
					</a>
					@if (isTestManagementTabVisibleSignal$()) {
						<a	mat-menu-item
							[disableRipple]="true"
							routerLink="/tia/v3/test-management"
							routerLinkActive="active">
							<div class="sl-flexbox-row sl-flexbox-align-center sl-flexbox-gap-8">
								<div>Test Management</div>
								<p-tag
									value="New"
									severity="warning"
									styleClass="sl-v1-tag sl-v1-tag-xs sl-v1-tag-outlined"
									[rounded]="true"></p-tag>
							</div>
						</a>
					}
				</mat-menu>
				<!-- @if (isTroubleshootingFeatureActiveSignal$()) {
					<sl-header-button
						text="Tests RCA"
						route="/troubleshooting"
						tag="Beta">
					</sl-header-button>
				} -->
				@if (isCockpitAllowedSignal$()) {
					<sl-header-button
						[matMenuTriggerFor]="cockpit"
						text="Cockpit"
						route="/cockpit"
						[expandable]="true">
					</sl-header-button>
				}
				<mat-menu
					#cockpit="matMenu"
					#cockpitRLA="routerLinkActive"
					class="header-dropdown-menu"
					routerLinkActive>
					<a
						mat-menu-item
						[disableRipple]="true"
						[routerLink]="['/cockpit', 'build-monitor']"
						routerLinkActive="active">
						<div>Build Monitor</div>
					</a>
					<a
						mat-menu-item
						[disableRipple]="true"
						[routerLink]="['/cockpit', 'agent-monitor']"
						routerLinkActive="active">
						<div>Live Agents Monitor</div>
					</a>

					@if (isLabMonitorFeatureActiveSignal$()) {
						<a
							mat-menu-item
							[disableRipple]="true"
							[routerLink]="['/cockpit', 'lab-monitor']"
							routerLinkActive="active">
							<div>Lab Monitor</div>
						</a>
					}
					@if (isCiMonitorFeatureActiveSignal$()) {
						<a
							mat-menu-item
							[disableRipple]="true"
							routerLink="/cockpit/ci-monitor"
							routerLinkActive="active">
							<div>CI Monitor</div>
						</a>
					}
					@if (isAuditLogsFeatureAvailableSignal$()) {
						<a
							mat-menu-item
							[disableRipple]="true"
							routerLink="/cockpit/audit-log"
							routerLinkActive="active">
							<div>Audit Log</div>
						</a>
					}
					@if (isLiveLabsEnabledSignal$()) {
						<a
							mat-menu-item
							[disableRipple]="true"
							routerLink="/cockpit/lab-live-monitor"
							routerLinkActive="active">
							<div>Live Labs Monitor</div>
						</a>
					}
				</mat-menu>
					@if(displayLiveLabsTabSignal$()) {
						<sl-header-button
							text="Live Labs Monitor"
							route="/lab-live-monitor">
						</sl-header-button>
					}
			</div>
			<div class="right sl-grid-columns">
				<sl-manual-test-launcher></sl-manual-test-launcher>
				<div
					*slLet="(permissionsState$ | async).unassignedAppsCount as unassignedAppsCount"
					class="sl-flexbox sl-flexbox-align-center sl-flexbox-justify-space-between sl-flexbox-gap-4">
					@if (isPublicApiFeatureActiveSignal$()) {
						<sl-header-button pTooltip="Run Manual Test" tooltipStyleClass="sl-v1-tooltip" tooltipPosition="bottom" icon="play_circle" (click)="showManualTest()"></sl-header-button>
						<sl-header-button pTooltip="Help" icon="help" tooltipStyleClass="sl-v1-tooltip" tooltipPosition="bottom" [matMenuTriggerFor]="helpMenu" (click)="infoMenuClick()"></sl-header-button>
					}
					<sl-header-button pTooltip="Settings" tooltipStyleClass="sl-v1-tooltip" tooltipPosition="bottom" route="settings" icon="settings" [badge]="unassignedAppsCount"></sl-header-button>
					<a
						class="user-avatar-wrapper"
						[matMenuTriggerFor]="userMenu">
						@if (!(impersonatedUser$ | async)) {
							<sl-avatar
								[clickable]="true"
								[value]="avatarUserDataSignal$()"
								propToDisplay="fullName"
								propToHash="email"
								size="30"
								styleClass="avatar-default avatar-black"
								[style]="{
									font: '12px Roboto, monospace',
									fontWeight: '400',
									letterSpacing: '0.03em'
								}"
								[pTooltip]="userTooltipSignal$()"
								tooltipStyleClass="sl-v1-tooltip sl-v1-tooltip-pre-line"
								tooltipPosition="bottom">
							</sl-avatar>
						} @else {
							<sl-avatar
								[value]="avatarUserDataSignal$()"
								propToDisplay="fullName"
								propToHash="email"
								size="30"
								styleClass="avatar-default avatar-black"
								[style]="{
									font: '12px Roboto, monospace',
									fontWeight: '400',
									letterSpacing: '0.03em'
								}"
								[pTooltip]="originalUserDetailsSignal$() | join:'\n'"
								tooltipStyleClass="sl-v1-tooltip sl-v1-tooltip-pre-line"
								tooltipPosition="bottom">
							</sl-avatar>
							<mat-icon
								class="impersonate-user-indication material-icons-outlined">
								supervised_user_circle
							</mat-icon>
						}
					</a>
					<mat-menu
						#helpMenu="matMenu"
						class="header-dropdown-menu help-menu">
						<button
							mat-menu-item
							disabled
							class="section">
							<span class="label sl-typography-body-bold">Help</span>
						</button>
						<button
							mat-menu-item
							(click)="sealightsApiReferenceClick()">
							<a	class="label section-item-label-flex"
								target="_blank"
								href="https://api-doc.sealights.io/">
								<div>SeaLights API Reference</div>
							</a>
						</button>
						<button
							mat-menu-item
							(click)="whatIsNewClick()">
							<a
								class="label section-item-label"
								href="https://docs.sealights.io/knowledgebase/whats-new/whats-new-highlights"
								target="_blank">
								<div>What's New</div>
							</a>
						</button>
						<button
							mat-menu-item
							(click)="agentReleaseNotesClicked()">
							<a	class="label section-item-label"
								href="https://www.sealights.io/release-notes/"
								target="_blank">
								<div>Agents Release Notes</div>
							</a>
						</button>
						<button
							mat-menu-item
							(click)="
								externalLinkClick('SeaLights Learning Center');
								sealightsLearningCenterClick()
							">
							<a
								class="label section-item-label-flex"
								href="https://www.sealights.io/sealights-learning-center/"
								target="_blank">
								<div>SeaLights Learning Center</div>
							</a>
						</button>
						<button
							mat-menu-item
							(click)="sealightsDocumentationClick()">
							<a	class="label section-item-label"
								href="https://sealights.atlassian.net/wiki/spaces/SUP/overview"
								target="_blank">
								<div>SeaLights Documentation</div>
							</a>
						</button>
						@if (!isCustomSupportFeatureActiveSignal$() || !isCustomSupportMessageSignal$()) {
							<button mat-menu-item (click)="supportPortalClick()">
								<a 	class="label section-item-label"
									href="http://support.sealights.co/servicedesk" target="_blank">
									<div class="sl-typography-nav">Support Portal</div>
								</a>
							</button>
						}
						@if (isCustomSupportFeatureActiveSignal$() && isCustomSupportMessageSignal$()) {
							<button mat-menu-item (click)="supportClick()">
									<div class="sl-typography-nav">Support</div>
							</button>
						}
						@if (isProductTourVisible$ | async) {
							<button	mat-menu-item
									(click)="productTourClick()">
								<span class="label section-item-label-flex">
									<div>Product Tour</div>
									<span class="tag sl-typography-body-small">
										new
									</span>
								</span>
							</button>
						}
						<button
							mat-menu-item
							disabled
							class="section">
							<span class="label sl-typography-body-bold">Legal</span>
						</button>
						<button	mat-menu-item
								(click)="privacyPolicyClick()">
							<a	class="label section-item-label"
								href="https://www.sealights.io/privacy-policy/"
								target="_blank">
								<div>Privacy Policy</div>
							</a>
						</button>
						<button	mat-menu-item
								(click)="termsOfUseClick()">
							<a	class="label section-item-label"
								href="https://www.sealights.io/terms-of-service/"
								target="_blank">
								<div>Terms of Use</div>
							</a>
						</button>
					</mat-menu>
					<mat-menu 	#userMenu="matMenu"
								class="header-dropdown-menu user-menu">
						@if (isSsoUserSignal$()) {
							<button	mat-menu-item
									class="user-auth">
								<mat-icon
									class="sso"
									svgIcon="user"></mat-icon>
								<span>SSO User</span>
							</button>
						}
						@if (impersonatedUser$ | async) {
							<button mat-menu-item
									class="sl-flexbox-row sl-flexbox-align-center sl-flexbox-gap-8"
									(click)="logoutFromImpersonatedUser()">
								<mat-icon>supervised_user_circle</mat-icon>
								<span class="label ellipsisize">Logout from "{{ userNameSignal$() }}"</span>
							</button>
							<mat-divider></mat-divider>
						}
						<button	mat-menu-item
								(click)="logout()">
							<div>Logout</div>
						</button>
						@if (!isSsoUserSignal$()) {
							<button	mat-menu-item
									(click)="showChangePasswordDialog()">
								<div>Change Password</div>
							</button>
						}
						@if (isUsersAndPermissionsFeatureActiveSignal$()) {
							<button mat-menu-item
									routerLink="/settings/profile">
								<div>My Profile</div>
							</button>
						}
						@if (isUserSealightsAdminSignal$()) {
							<button	mat-menu-item
									class="toggle-superuser-messages"
									(click)="toggleHideSuperuserMessages()">
								@if (hideSuperuserMessages) {
									<div>Show superuser messages</div>
								} @else {
									<div>Hide superuser messages</div>
								}
							</button>
						}
					</mat-menu>
				</div>
			</div>
		</div>
	</mat-toolbar>
</div>
