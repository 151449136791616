import { InjectionToken } from '@angular/core';

export interface CustomMaterialIconsProviderOptions {
	debug?: boolean;
}

export const defaultCustomMaterialIconsProviderOptions: CustomMaterialIconsProviderOptions = {
	debug: false
};

export const CUSTOM_MATERIAL_ICON_OPTIONS = new InjectionToken<CustomMaterialIconsProviderOptions>(
	'CUSTOM_MATERIAL_ICON_OPTIONS',
	{ factory: (): CustomMaterialIconsProviderOptions => defaultCustomMaterialIconsProviderOptions }
);
