import { isString } from 'lodash-es';

import { GenericAnalyticsEventPayloadBuilder } from './generic-analytics-event-payload.builder';

export class HeaderAnalyticsEventPayloadBuilder extends GenericAnalyticsEventPayloadBuilder {
	withExternalLink(name: string | undefined): this {
		if (isString(name)) {
			this.payload.external_link_name = name;
		} else {
			this.payload.external_link_name = null;
		}

		return this;
	}
}
