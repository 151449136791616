import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { APP_CONFIG, ApplicationConfigProviderOptions } from './application-config-provider.model';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function provideApplicationConfig(options: ApplicationConfigProviderOptions): EnvironmentProviders {
	return makeEnvironmentProviders([
		{
			provide: APP_CONFIG,
			useValue: options?.config
		},
	]);
}
