import { createSelector } from '@ngrx/store';

import { GlobalState } from '../models/global-store.model';

export const selectGlobalStoreFeature = (state) => state.globalStore;

export const selectLoginAsUserId = createSelector(
	selectGlobalStoreFeature,
	(state: GlobalState) => state.loginAsUserId
);
