import { PagedApiRequestParams } from '@@shared/common';
import { TimeState } from '@@shared/stopwatch';

export enum TestContext {
	ByAppBranchBuild = 'byAppBranchBuild',
	ByLab = 'byLab'
}

export interface Application {
	name: string;
}

export interface Branch {
	name: string;
	branchName: string;
}

export interface Build {
	name: string;
	buildName: string;
	bsid: string;
	generated: string;
}

export interface Lab {
	name: string;
	active: boolean;
	appName?: string;
	branchName?: string;
	buildName?: string;
	type: 'integration' | 'component';
	timestamp: string;
	labId: string;
	isSealightsGeneratedGuid: boolean;
	mainBuild?: {
		appName: string;
		branchName: string;
		buildName: string;
		buildSessionId: string;
	};
}

export interface LabContext {
	appName: string;
	branchName: string;
	buildName?: string;
}

export interface LabListItem {
	labId: string;
	labAlias?: string;
}

export interface LabIdsRequestParams extends PagedApiRequestParams {
	appName: string;
	branchName: string;
	buildName: string;
}

export interface TiaContext {
	app: Application;
	branch: Branch;
	build: Build;
}

export type TestSource = 'list' | 'single';

export interface TestListItem {
	name: string;
	exclude: boolean;
}

export interface TestListSettings {
	items: TestListItem[];
	fileName?: string;
	allTestsEnded?: boolean;
	paging?: {
		nextPage: number;
	};
}

export interface FormState {
	app?: Application;
	branch?: Branch;
	build?: Build;
	useCustomLab?: boolean;
	customLabId?: string;
	lab?: Lab;
	labContext?: LabContext;
	testStage?: string;
	test?: string;
	recommendedTests?: TestListSettings;
}

export interface BuildSession {
	customerId: string;
	appName: string;
	buildName: string;
	branchName: string;
	buildSessionId: string;
}

export type ExecutionType = 'executionIdStarted' | 'executionIdEnded' | 'testStart' | 'testEnd';

export enum ExecutionStep {
	Init = 0,
	TestConfig = 1,
	ExecStart = 2,
	TestStart = 3,
	TestConfirm = 4,
	TestEnd = 5,
	ExecEnd = 6,
	Pause = 7,
	Resume = 8
}

export interface TestCounters {
	testStart: number;
	testEnd: number;
	execStart: number;
	execEnd: number;
}

export const TEST_EVENT_FRAMEWORK = 'manual';

export enum TestResult {
	Passed = 'passed',
	Failed = 'failed',
	Cancelled = 'cancelled'
}

export interface TestsEvent {
	meta: {
		counters: TestCounters;
	};
	timestamp?: number;
	executionId: string;
	framework: string;
	testName?: string;
	testPath?: string[];
	result?: string;
	duration?: number;
	executor?: {
		name: string;
		email: string;
	};
	type: ExecutionType;
}

export interface TestEnvironment {
	labId: string;
	testStage: string;
}


export interface TestEventsWrapper {
	customerId: string;
	appName: string;
	branch: string;
	build: string;
	executionId: string;
	environment: TestEnvironment;
	testSelectionStatus: string;
	events: TestsEvent[];
}

export interface RunningTest extends TimeState {
	active: boolean;
	duration: number;
	paused: boolean;
	ended: boolean;
	testResult?: TestResult;
}

export const TEST_PERSISTENT_STATE_KEY = 'manual-test';

export interface TestPersistentState {
	formState: FormState;
	testEventsWrapper: Partial<TestEventsWrapper>;
	runningTest: RunningTest;
}

export const LOCKED_TEST_STAGE_NAME = 'Manual Tests';
