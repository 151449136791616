import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs';

import { User } from '@@settings/model/permissions.model';

import * as GlobalStoreActions from '../actions/global-store.actions';

@Injectable({
	providedIn: null
})
export class GlobalStoreEffects {
	readonly setImpersonateUser$ = createEffect(() =>
		inject(Actions).pipe(
			ofType(GlobalStoreActions.globalStoreSetLoginAsUser),
			tap(({ userId, data }) => {
				if (userId) {
					localStorage.setItem('impersonatedUserId', userId);
					const currentUserData = localStorage.getItem('userData');
					if (currentUserData) {
						const parsedData: User = JSON.parse(currentUserData) as User;
						parsedData.role = data.role;
						parsedData.email = data.email;
						localStorage.setItem('userData', JSON.stringify(parsedData));
					}
				} else {
					localStorage.removeItem('impersonatedUserId');
				}
			}),
			map(({ userId }) => GlobalStoreActions.globalStoreSetLoginAsUserSuccess({ userId }))
		));

	readonly loadImpersonatedUser$ = createEffect(() =>
		inject(Actions).pipe(
			ofType(GlobalStoreActions.globalStoreLoadImpersonatedUserId),
			map((() => GlobalStoreActions.globalStoreLoadImpersonatedUserIdSuccess({ userId: localStorage.getItem('impersonatedUserId') })))
		)
	);
}
