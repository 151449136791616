export enum TrackActionType {
	ClickStart = 'tia_click_start_manual_test',
	ClickSubmit = 'tia_click_submit_manual_test',
	ClickCancel = 'tia_click_cancel_manual_test',
	ClickStartNew = 'tia_click_start_new_manual_test',
	ClickRestart = 'tia_click_restart_manual_test'
}

export type AnalyticsPayloadContextProvider<T> = () => T;

export interface AnalyticsEventPayloadContext {
	buttonLocation: string;
	longestExecutionDuration?: number;
	appName?: string;
	testStageName?: string;
}

export interface ManualTestPayloadContext {
	appName: string;
	branchName: string;
	testStageName: string;
}
