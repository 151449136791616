import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { CustomMaterialIconsProviderOptions } from './custom-material-icons.model';

const iconsFolder = '/assets/icons/material';
const iconUri = (iconName: string): string => `${iconsFolder}/${iconName}.svg`;

export const registerCustomMaterialIcons = (matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer, options: CustomMaterialIconsProviderOptions) => (): Promise<void> => {
	const customIconNames: ReadonlyArray<{ iconFileName: string; iconName }> = [
		{
			iconFileName: 'git',
			iconName: 'git'
		},
		{
			iconFileName: 'github',
			iconName: 'github'
		},
		{
			iconFileName: 'gitlab',
			iconName: 'gitlab'
		},
		{
			iconFileName: 'bitbucket',
			iconName: 'bitbucket'
		},
		{
			iconFileName: 'bitbucket',
			iconName: 'Bitbucket'
		},
		{
			iconFileName: 'vsts',
			iconName: 'vsts'
		},
		{
			iconFileName: 'gates',
			iconName: 'gates'
		},
		{
			iconFileName: 'arrow-left',
			iconName: 'arrow_left'
		},
		{
			iconFileName: 'greater-equal',
			iconName: 'greater_equal'
		},
		{
			iconFileName: 'less-equal',
			iconName: 'less_equal'
		},
		{
			iconFileName: 'equals',
			iconName: 'equals'
		},
		{
			iconFileName: 'stop-circle',
			iconName: 'stop_circle'
		},
		{
			iconFileName: 'expand-all',
			iconName: 'expand_all'
		},
		{
			iconFileName: 'collapse-all',
			iconName: 'collapse_all'
		},
		{
			iconFileName: 'debug',
			iconName: 'debug'
		},
		{
			iconFileName: 'not-allowed',
			iconName: 'not_allowed'
		},
		{
			iconFileName: 'unscoped',
			iconName: 'unscoped'
		},
		{
			iconFileName: 'api',
			iconName: 'api'
		},
		{
			iconFileName: 'nodejs',
			iconName: 'nodejs'
		},
		{
			iconFileName: 'java',
			iconName: 'java'
		},
		{
			iconFileName: 'perl',
			iconName: 'perl'
		},
		{
			iconFileName: 'python',
			iconName: 'python'
		},
		{
			iconFileName: 'dotnet',
			iconName: 'dotnet'
		},
		{
			iconFileName: 'reset-password',
			iconName: 'reset_password'
		},
		{
			iconFileName: 'users',
			iconName: 'users'
		},
		{
			iconFileName: 'user',
			iconName: 'user'
		},
		{
			iconFileName: 'edit',
			iconName: 'edit'
		},
		{
			iconFileName: 'email',
			iconName: 'email'
		},
		{
			iconFileName: 'delete',
			iconName: 'delete'
		},
		{
			iconFileName: 'toggle-on',
			iconName: 'toggle_on'
		},
		{
			iconFileName: 'toggle-off',
			iconName: 'toggle_off'
		},
		{
			iconFileName: 'plus',
			iconName: 'plus'
		},
		{
			iconFileName: 'camera',
			iconName: 'camera'
		},
		{
			iconFileName: 'spinner-circles',
			iconName: 'spinner_circles'
		},
		{
			iconFileName: 'copy',
			iconName: 'copy'
		},
		{
			iconFileName: 'insights',
			iconName: 'insights'
		},
		{
			iconFileName: 'csv',
			iconName: 'csv'
		},
		{
			iconFileName: 'slack-color',
			iconName: 'slack_color'
		},
		{
			iconFileName: 'email-color',
			iconName: 'email_color'
		},
		{
			iconFileName: 'branch',
			iconName: 'branch'
		}
	];

	return new Promise(resolve => {

		customIconNames.forEach((item) =>
			matIconRegistry.addSvgIcon(
				item.iconName,
				domSanitizer.bypassSecurityTrustResourceUrl(iconUri(item.iconFileName))
			)
		);

		if (options.debug) {
			console.log(`[CUSTOM MATERIAL ICONS PROVIDER] Successfully registered ${customIconNames?.length} custom icons`);
			console.log('[CUSTOM MATERIAL ICONS PROVIDER] Here is the list of registered items', customIconNames);
		}

		return resolve();
	});
};
